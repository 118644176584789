import { Component, Input } from '@angular/core';
import { OnChanges } from "@angular/core";

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {

  @Input() visible = true;
  visibleChangeValue: any;

  ngOnChanges() {
      this.visibleChange();
  }

  visibleChange() {
      this.visibleChangeValue = this.visible;
  }

}
