import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RetailComponent } from './industries/retail/retail.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { EcommerceComponent } from './industries/ecommerce/ecommerce.component';
import { LogisticsComponent } from './industries/logistics/logistics.component';
import { ManufacturingComponent } from './industries/manufacturing/manufacturing.component';
import { BankingComponent } from './industries/banking/banking.component';
import { ErpComponent } from './services/erp/erp.component';
import { VmsComponent } from './services/vms/vms.component';
import { MsofficeComponent } from './services/msoffice/msoffice.component';
import { UpgradebussinesscentralComponent } from './services/upgradebussinesscentral/upgradebussinesscentral.component';
import { SystemintegrationComponent } from './services/systemintegration/systemintegration.component';
import { WebdevelopComponent } from './services/webdevelop/webdevelop.component';
import { SupportComponent } from './services/support/support.component';
import { ItstrategyComponent } from './services/itstrategy/itstrategy.component';
import { ItsecurityComponent } from './services/itsecurity/itsecurity.component';
import { BusinesscentralComponent } from './products/businesscentral/businesscentral.component';
import { DynamicsnavComponent } from './products/dynamicsnav/dynamicsnav.component';
import { LscentralComponent } from './products/lscentral/lscentral.component';
import { LshospitalityComponent } from './products/lshospitality/lshospitality.component';
import { MsazureComponent } from './products/msazure/msazure.component';
import { PowerbiComponent } from './products/powerbi/powerbi.component';
import { HrmsComponent } from './products/hrms/hrms.component';
import { Msoffice365Component } from './products/msoffice365/msoffice365.component';
import { MssharepointComponent } from './products/mssharepoint/mssharepoint.component';
import { VeritasComponent } from './products/veritas/veritas.component';
import { HrmsonlineComponent } from './digital/hrmsonline/hrmsonline.component';
import { VmsonlineComponent } from './digital/vmsonline/vmsonline.component';
import { CloudComponent } from './digital/cloud/cloud.component';
import { WebappdevelopComponent } from './digital/webappdevelop/webappdevelop.component';
import { MobileappdevelopComponent } from './digital/mobileappdevelop/mobileappdevelop.component';
import { ChatbotComponent } from './digital/chatbot/chatbot.component';
import { AboutusComponent } from './about/aboutus/aboutus.component';
import { WorkwithusComponent } from './about/workwithus/workwithus.component';
import { BlogsComponent } from './about/blogs/blogs.component';
import { TrendmicroComponent } from './products/trendmicro/trendmicro.component';
import { BlogdetailComponent } from './about/blogs/blogdetail/blogdetail.component';
import { LoadingComponent } from './loading/loading.component';
import { CareerdetailComponent } from './about/workwithus/careerdetail/careerdetail.component';
import { SteelComponent } from './industries/manufacturing/steel/steel.component';
import { FoodComponent } from './industries/manufacturing/food/food.component';
import { NotfoundComponent } from './notfound/notfound.component';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactusComponent,
    RetailComponent,
    HospitalityComponent,
    EcommerceComponent,
    LogisticsComponent,
    ManufacturingComponent,
    BankingComponent,
    ErpComponent,
    VmsComponent,
    MsofficeComponent,
    UpgradebussinesscentralComponent,
    SystemintegrationComponent,
    WebdevelopComponent,
    SupportComponent,
    ItstrategyComponent,
    ItsecurityComponent,
    BusinesscentralComponent,
    DynamicsnavComponent,
    LscentralComponent,
    LshospitalityComponent,
    MsazureComponent,
    PowerbiComponent,
    HrmsComponent,
    Msoffice365Component,
    MssharepointComponent,
    VeritasComponent,
    HrmsonlineComponent,
    VmsonlineComponent,
    CloudComponent,
    WebappdevelopComponent,
    MobileappdevelopComponent,
    ChatbotComponent,
    AboutusComponent,
    WorkwithusComponent,
    BlogsComponent,
    TrendmicroComponent,
    BlogdetailComponent,
    LoadingComponent,
    CareerdetailComponent,
    SteelComponent,
    FoodComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NotifierModule.withConfig(customNotifierOptions),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
