<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Work With Us</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Work With Us</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div *ngIf="knowMore==false" class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <!-- <h1>Careers</h1> -->
                            <h2>Join Enovasions</h2>
                            <p>
                                We work with local companies to international brands and organisations on projects ranging from mobile apps to e-commerce sites. We do this in an
                                environment that encourages thought, leadership and creative freedom.
                            </p>
                            <p>
                                If you don’t see something that suits, feel free to get in touch regardless. We’d love to chat and see if we can keep you in mind for future roles, or create new
                                ones. If you're a student looking to start a career in the creative industries, then we'd also like to hear from you.
                            </p>

                            <div class="row" style="margin-top: 30px;">
                                <div class="col-sm-4 text-center">
                                    <img src="assets/images/ic_growth_bosleo.svg" alt="" />
                                    <h3>GROWTH AT ENOVASIONS</h3>
                                    <p>
                                        We want you to develop and learn. Every employee has the opportunity to grow in their role(s) at Brave through hands-on experience, paid learning and workshops.
                                    </p>
                                </div>
                                <!-- <div class="col-sm-3 text-center">
                                    <img src="assets/images/ic_flexible_hours.svg" alt="" />
                                    <h3>FLEXIBLE HOURS</h3>
                                    <p>
                                        As an agency built on creativity, we want you at your best. That's why we work on select days and even adjust our hours depending on life's important moments.
                                    </p>
                                </div> -->
                                <div class="col-sm-4 text-center">
                                    <img src="assets/images/ic_compensation.svg" alt="" />
                                    <h3>COMPENSATION</h3>
                                    <p>
                                        We value the members of our team, and pay competitive wages based on position and experience.
                                    </p>
                                </div>
                                <div class="col-sm-4 text-center">
                                    <img src="assets/images/ic_irresistible_culture.svg" alt="" />
                                    <h3>IRRESISTIBLE CULTURE</h3>
                                    <p>
                                        At the risk of sounding cliche, we fight for culture at every turn. You won't find any cubicles, or micro managers within these walls.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="knowMore==false" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 class="text-center">OPEN POSITIONS</h2>
                            <div class="row">
                                <div class="col-sm-6" style="padding-right: 20px;">
                                    <h3>Sr. Techno-Functional Consultant</h3>
                                    <p>
                                        We are seeking a motivated, independent, and organized individual who can Work from Home (WFH) on Fiji Time, for the position of Senior Microsoft Dynamics 365 Business Central / NAV/LS Retail/LS Central Techno-Functional Specialist to join our team on a full-time basis. The successful candidate will need to have a good knowledge of the LS Central and Dynamics 365 Business Central, review client business processes and re-engineer the clients processes to improve efficiency, and performing many of the functional tasks required for a successful NAV/LS Retail implementation. This is a perfect role for a senior Dynamics NAV/LS Retail Techno-Functional Consultant with great communication skills.
                                    </p>
                                    <a (click)="gotoCareerDetail('srtechno')" class="know-more-link"><b>Know More</b></a>
                                    <button (click)="gotoPositionForm()" class="link-with-arrow apply-position-btn pull-right" data-position="Android Developer" type="button">apply here</button>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <h3>IOS Developer</h3>
                                    <p>
                                        We are looking for an iOS developer responsible for the development and maintenance of applications aimed at a range of iOS devices including mobile phones and tablet computers. Your primary focus will be development of iOS applications and their integration with back-end services. You will be working alongside other engineers and developers working on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and the creation of quality products is essential.
                                    </p>
                                    <a routerLink="/work-with-us/ios-developer-position" class="know-more-link"><b>Know More</b></a>
                                    <button class="link-with-arrow apply-position-btn pull-right" data-position="ios developer" type="button">apply here</button>
                                </div> -->
                            </div>
                            <!-- <div class="row" style="margin-top: 20px;"> 
                                <div class="col-sm-6" style="padding-right: 20px;">
                                    <h3>Technical Lead/Laravel Developer</h3>
                                    <p>
                                        We are looking for a passionate Laravel Lead/Developer to join our team. The ideal candidate will be an enthusiastic developer eager to learn and grow his/her skills in the field. You will be working side-by-side with other developers in an emerging agile environment including practices such as continuous integration, TDD, retrospectives, refactoring skills and more.
                                    </p>
                                    <a routerLink="/work-with-us/laravel-developer-position" class="know-more-link"><b>Know More</b></a>
                                    <button class="link-with-arrow apply-position-btn pull-right" data-position="Laravel Developer" type="button">apply here</button>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div *ngIf="knowMore==true" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone" style="overflow: hidden;">
                            <h2 class="text-center">Open <span>Positions</span></h2>
                            <div *ngIf="positionDetail=='srtechno'"> 
                                <h3>Sr. Techno-Functional Consultant</h3>
                                <p>
                                    We are seeking a motivated, independent, and organized individual who can Work from Home (WFH) on Fiji Time, for the position of Senior Microsoft Dynamics 365 Business Central / NAV/LS Retail/LS Central Techno-Functional Specialist to join our team on a full-time basis. The successful candidate will need to have a good knowledge of the LS Central and Dynamics 365 Business Central, review client business processes and re-engineer the clients processes to improve efficiency, and performing many of the functional tasks required for a successful NAV/LS Retail implementation. This is a perfect role for a senior Dynamics NAV/LS Retail Techno-Functional Consultant with great communication skills.
                                </p>
                                <h3>Responsibilities</h3>
                                <ul>
                                    <li><i></i>Managing the implementation of Dynamics 365 Business Central and LS Central through the full project life cycle.</li>
                                    <li><i></i>Technical consultant having developed customized solutions.</li>
                                    <li><i></i>Carrying out upgrades and data migrations.</li>
                                    <li><i></i>Providing support and training where necessary.</li>
                                    <li><i></i>Leads project teams through design workshops and pilot environments.</li>
                                    <li><i></i>Preparation of time and cost estimates for completing projects.</li>
                                    <li><i></i>Mentoring junior consultants where required.</li>
                                    <li><i></i>Training customer resources to successfully use and maintain Dynamics 365 Business Central and LS Central based Life Cycle solutions.</li>
                                    <li><i></i>Understands customer needs. Perform gap analysis between package functionality and customer requirements.</li>
                                    <li><i></i>Actively establishes relationships with all levels within the customer organization.</li>
                                </ul>
                                <h3>Qualification</h3>
                                <ul>
                                    <li><i></i>Degree in B.Tech/B.E. - Computers, B.Sc - Computers, B.Com - Commerce, BCA - Computers or related discipline or equivalent.</li>
                                    <li><i></i>7+ years of professional experience in consulting and implementation of Microsoft Dynamics NAV/LS Retail.</li>
                                    <li><i></i>Microsoft Certified in NAV/LS Retail exams.</li>
                                    <li><i></i>Good Knowledge of C/AL, Reports and MS SQL, .NET, AL Programing Language.</li>
                                    <li><i></i>Experience in Retail and/or Distribution industries is preferred.</li>
                                    <li><i></i>Proven track record for problem solving and rolling out of software solutions including documentation.</li>
                                    <li><i></i>Team player, comfortable business presentations.</li>
                                    <li><i></i>Organised, analytical, result-oriented.</li>
                                    <li><i></i>Must have excellent English written and verbal communication skills.</li>
                                    <li><i></i>Technical and Functional proficiency.</li>
                                </ul>
                                <button (click)="gotoPositionForm()" class="link-with-arrow apply-position-btn pull-left" data-position="Android Developer" type="button">apply here</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div id="career-form" class="career-form section">
                                <div class="container">
                                    <h2 class="text-center">APPLY FOR THIS POSITION</h2>
                                    <div class="career-form">
                                        <!-- <form id="career" enctype="multipart/form-data"> -->
                                            <input type="hidden" name="_token" value="dzrWSK87AtRlAxBaDao5ewshyPkp6veBdE3qLOfG">
                                            <div class="row">
                                                <input type="hidden" name="position" value="">
                                                <div class="col-sm-12">
                                                    <div id="position" class="row">
                                                        <div class="col-sm-4 col-xs-6 padding-right20">
                                                            <div class="position-radio">
                                                                <input id="android-developer" type="radio" (click)="setAppliedPosition(0, 'Sr. Techno-Functional Consultant')" [checked]="radiooption == 0" value="Sr. Techno-Functional Consultant">
                                                                <label for="android-developer" class="position-radio-btn">Sr. Techno-Functional Consultant</label>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-sm-4 col-xs-6 padding-right20">
                                                            <div class="position-radio">
                                                                <input id="ios-developer" type="radio" (click)="setAppliedPosition(1, 'IOS Developer')" [checked]="radiooption == 1" value="IOS Developer">
                                                                <label for="ios-developer" class="position-radio-btn">IOS Developer</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 col-xs-6 padding-right20">
                                                            <div class="position-radio">
                                                                <input id="laravel-developer" type="radio" (click)="setAppliedPosition(2, 'Laravel Developer')" [checked]="radiooption == 2" value="Laravel Developer">
                                                                <label for="laravel-developer" class="position-radio-btn">Laravel Developer</label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div> 
                                                <div class="col-sm-6 padding-right20">
                                                    <div class="input-section">
                                                        <input type="text" class="input-section__field " placeholder="First name*" [(ngModel)]="form_data.FirstName" (keypress)="blockSpecialChar($event)">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-section">
                                                        <input type="text" class="input-section__field " placeholder="Last name*" [(ngModel)]="form_data.LastName" (keypress)="blockSpecialChar($event)" >
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 padding-right20">
                                                    <div class="input-section">
                                                        <input type="email" class="input-section__field " placeholder="Email*" [(ngModel)]="form_data.Email" (blur)="validateEmail($event)">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-section">
                                                        <!-- <input type="tel" class="input-section__field " placeholder="Phone*" [(ngModel)]="form_data.Phone_Number" (keydown)="isNumberKey($event)" maxlength="10"> -->
                                                        <input type="tel" class="input-section__field " placeholder="Phone*" [(ngModel)]="form_data.Phone_Number" (keydown)="isNumberKey($event)" id="phone">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 padding-right20">
                                                    <div class="input-section">
                                                        <input type="url" class="input-section__field" placeholder="Linkedin profile" [(ngModel)]="form_data.Linkedin_Profile">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-section">
                                                        <input type="text" class="input-section__field " placeholder="Address*" [(ngModel)]="form_data.Location">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="input-section">
                                                        <textarea class="input-section__field" placeholder="How did you hear about this job?" [(ngModel)]="form_data.Hear_About"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12">
                                                    <div class="input-section">
                                                        <textarea class="input-section__field" placeholder="Introduce yourself" [(ngModel)]="form_data.Intro_Yourself"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8 col-xs-12">
                                                    <div class="file-upload-section">
                                                        <div class="button file-upload-button ">
                                                            <span class="file-upload-button__inner"><svg id="svg2" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" height="20" width="20" version="1.1" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" viewBox="0 0 30 30"><g id="layer1" transform="translate(0 -1022.4)"><g id="text3336" fill="#AB987A" transform="translate(-.37914 -.2532)"><path id="path3341" fill="#AB987A" d="m29.379 1035.7v3.8181q0 0.7955-0.55682 1.3523t-1.3523 0.5568h-8.2727v8.2728q0 0.7954-0.55682 1.3522-0.55682 0.5569-1.3523 0.5569h-3.8182q-0.79545 0-1.3523-0.5569-0.55682-0.5568-0.55682-1.3522v-8.2728h-8.2727q-0.79545 0-1.3523-0.5568-0.55682-0.5568-0.55682-1.3523v-3.8181q0-0.7955 0.55682-1.3523t1.3523-0.5568h8.2727v-8.2728q0-0.7954 0.55682-1.3522t1.3523-0.5568h3.8182q0.79546 0 1.3523 0.5568 0.55682 0.5568 0.55682 1.3522v8.2728h8.2727q0.79545 0 1.3523 0.5568 0.55682 0.5568 0.55682 1.3523z"/></g></g></svg>ATTACH RESUME<span style="color: #FF533D;"><b>*</b></span></span>
                                                            <input type="file" (change)="handleFileInput($event)" id="upload-resume" class="file-input" multiple="" >
                                                        </div>
                                                        <span id="file-name" class="file-name">{{fileToUploadName}}</span>
                                                    </div>
                                                    <span class="hint">(doc, docx or pdf)</span>
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <button (click)="applyPosition(form_data)" class="link-with-arrow career-submit-btn fright" style="float: right;">Submit</button>
                                                </div>
                                            </div>
                                        <!-- </form> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>

    </div>
</div>
