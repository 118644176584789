<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Power BI</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Power BI</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">It’s all yours with Dynamics 365 Business Central or LS Central</h2>
                                <p>The intelligent cloud, powered by Microsoft, is just another part of the secret sauce behind your industry-specific deployment enabled by Enovasions. Having the right information at all levels of the organization makes spotting trends faster and easier. Companies with access to rich intelligence and workflow automation tools can create a substantial competitive advantage. When you can quickly put your insights into action, you’ll outpace your competition every time.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="700" height="466" src="assets/images/powerbi.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/powerbi.png 700w, assets/images/powerbi-300x200.png 300w" sizes="(max-width: 700px) 100vw, 700px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <img width="700" height="483" src="assets/images/power-bi-flow-1-700x483-1.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/power-bi-flow-1-700x483-1.png 700w, assets/images/power-bi-flow-1-700x483-1-300x207.png 300w" sizes="(max-width: 700px) 100vw, 700px" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="margin-bottom: 40px;">
                                <h2 style="font-size: 22px;">Power BI that Help your Business Get Smarter & Faster.</h2>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">POWER BI</h2>
                                </div>
                                <div>
                                    <p>A cloud-based business intelligence solution that enables data modeling, discovery, and interactive visual reporting.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">CORTANA INTELLIGENCE SUITE</h2>
                                </div>
                                <div>
                                    <p>Big data and advances analytics suite with voice recognition, Azure machine learning, and loT sensor capabilities.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">FLOW & LOGIC APPS</h2>
                                </div>
                                <div>
                                    <p>Similar solutions for power users and pro developers to manage task automation, integrations, and workflows in the cloud.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center">
                        <div class="backgroundColor" style="overflow: hidden; padding-top: 0;">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">POWER APPS</h2>
                                </div>
                                <div>
                                    <p>Build custom mobile apps connected to the common data model to run on any tablet or smartphone, securely mobilising any business process.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">COMMON DATA MODEL</h2>
                                </div>
                                <div>
                                    <p>A cloud database with shared entities across ERP, CRM, and Microsoft cloud apps that simplifies extensibility and integrations.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">AZURE CLOUD</h2>
                                </div>
                                <div>
                                    <p>This is the foundation of the secure, enterprise-level intelligent cloud, including all the components that power Dynamics 365 Business Central or LS Central, including Azure SQL and Azure Active Directory.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Bring Your Company Data to Life with Microsoft Power BI</h2>
                                <p>It is a beautiful suite of business analytics tools to analyze data and share insights. And it’s yours with Dynamics 365 Business Central or LS Central — including industry-specific business process content packs from Enovasions. Rather than start from scratch, leverage our experience and pre-built solutions to take Dynamics 365 Business Central or LS Central workspaces to a whole new level with embedded Power BI. And, rather than treating reporting as an afterthought, Enovasions brings actionable business intelligence to the forefront of every ERP project.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <iframe class="center-block" allowfullscreen title="youtube Video Player" src="https://www.youtube.com/embed/_OOyJfszJXY?autoplay=1&mute=1"></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 20px;">
                        <div class="backgroundColorNone">
                            <div class="col-lg-3 col-md-3 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 20px;">Gain Complete Insight Into Your Company</h2>
                                <p>Gone are the days of manually generating reports and sharing with your team. Power BI consolidates information and data across systems, providing a comprehensive view of customer service, financials, inventory, sales and much more. With instant access to dashboards, KPIs and real-time company data, you can monitor change and quickly capitalize on new opportunities.</p>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 20px;">Boost Productivity and Collaboration</h2>
                                <p>Too often in business, employees and stakeholders make decisions based on inconsistent or incomplete data. With Power BI Groups, you can easily collaborate with stakeholders to ensure everyone has accurate data and boost productivity across the organization.</p>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-12" style="padding-right: 20px">
                                <h2 style="font-size: 20px;">Standardize KPIs and Create Accountability</h2>
                                <p>Quantifying goals and confidently tracking progress isn’t always an exact science, and this uncertainty can stall your long-term goals. With thorough reports, you can assign accountability to particular KPIs, align business activity with strategic goals and reach milestones faster.</p>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <h2 style="font-size: 20px;">Have All Your Data in One Place</h2>
                                <p>Access your data regardless of its form or where it lives, including Excel spreadsheets, cloud services, streaming data and on-premises databases. Implementing Power BI provides a holistic view of the most essential metrics for your business. With touch-enabled native apps for Windows, iOS and Android, you can access all your data from anywhere and eliminate duplicate content.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 20px; text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 style="font-size: 22px;">DYNAMICS 365 BUSINESS CENTRAL OR LS CENTRAL WITH POWER BI IS SO SMART</h2>
                            <h2 style="font-size: 22px;">IT’S THE LAST ERP + CRM + BI TOOL YOU’LL EVER NEED</h2>
                            Check out some example Power BI dashboards (and this is just finance!)
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Cortana Intelligence</h2>
                                <p>Research reports quote that more than 50% of all searches will be voice searches and about 30% of all the searches will be done without a screen in the near future. These facts reassure that voice search/command is growing at a rapid pace, giving the power of business intelligence on the go.</p>
                                <p>Cortana is Microsoft’s personal digital assistant and an integral part of any device that runs Windows 10. By integrating with Power BI, Cortana can give you answers directly from key business data .Once you integrate Cortana and Microsoft Power BI, you can do fantastic stuff with Business Intelligence in a voice based, self-service manner. You can query your data, reports and dashboards Directly with Cortana.</p>
                                <p>Questions can be asked in Cortana using either voice or by typing – here are some example responses.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px; margin: 50px 0;">
                                <img width="549" height="305" src="assets/images/cortana.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/cortana.png 549w, assets/images/cortana-300x167.png 300w" sizes="(max-width: 549px) 100vw, 549px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 style="font-size: 22px;">MICROSOFT FLOW & AZURE LOGIC APPS</h2>
                            <h2 style="font-size: 22px;">UNIVERSAL WORKFLOW, DECISION-MAKING, AND INTEGRATION ENGINES</h2>
                            <p>You’ll wonder how you did it before Microsoft Flow, an intuitive tool that streamlines notifications, syncs data between systems, automates workflows, and more — leading to faster, more efficient business processes. Microsoft Flow is intended for user defined workflows. To manage integration scenarios at the enterprise level, leverage Azure Logic Apps.</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Common Data Model</h2>
                                <p>Microsoft Common Data Model (CDM) is a secure cloud business database that contains standard business entities that are shared across Dynamics 365 Business Central or LS Central (ERP and CRM), Power BI, Office 365, and other Microsoft cloud solutions. Microsoft Flow and Azure Logic Apps allow you to push and pull data from the CDM with simple connectors. You don’t have to think about where the data is stored or how it is structured and shared. Now you can break down the silos and see a unified view across apps.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="1024" height="487" src="assets/images/productivity-1024x487-1.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/productivity-1024x487-1.png 1024w, assets/images/productivity-1024x487-1-300x143.png 300w, assets/images/productivity-1024x487-1-768x365.png 768w" sizes="(max-width: 1024px) 100vw, 1024px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px; text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 style="font-size: 22px;">Experience Your Data. Any data.</h2>
                            <p>Connect to what matters to you. Excel spreadsheets, on-premises data sources, big data, streaming data, and cloud services: it doesn’t matter what type of data you want or where it lives, connect to hundreds of data sources with Power BI. More sources added monthly.</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <img width="1920" height="551" src="assets/images/tools-1.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/tools-1.png 1920w, assets/images/tools-1-300x86.png 300w, assets/images/tools-1-1024x294.png 1024w, assets/images/tools-1-768x220.png 768w, assets/images/tools-1-1536x441.png 1536w" sizes="(max-width: 1920px) 100vw, 1920px" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; POWER BI</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
