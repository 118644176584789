<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">System Integration</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">System Integration</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 18px;">Seamlessly Integrate Data and Processes</h2>
                            <div>
                                <p>Digital transformation is a business reality. As a growing business, you want to make sure that implementing or upgrading a new system will have minimal interference to your operations and infrastructure. Enovasions can help seamlessly integrate applications, data, and processes. This will enable you to operate at exceptional speed and agility in an ever-changing world.</p>
                                <p>Leverage the power, security, and flexibility of Azure — Microsoft’s integrated cloud services platform that IT professionals use to build, deploy, and manage applications through a global network of datacenters. With Azure, you can deploy anywhere with your choice of tools — connecting to the cloud or on-premises with hybrid cloud capabilities for maximum portability and value from your existing investments.</p>
                            </div>
                        </div>
                    </div>

                     <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="min-height: 320px;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 18px;">Microsoft Flow – Automate Process & Tasks</h2>
                                <p>Work less, do more. Create automated workflows between your favorite apps and services to get notifications, synchronize files, collect data, and more.  The simplified interface removes the need to code secure handshakes between apps, logging rules and mapping, as this is all performed as part of the Flow eco-system.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="1280" height="426" src="assets/images/Flow-Devices3.gif" class="attachment-full size-full center-block" alt="" loading="lazy" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <img width="1525" height="1078" src="assets/images/integration1.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/integration1.png 1525w, assets/images/integration1-300x212.png 300w, assets/images/integration1-1024x724.png 1024w, assets/images/integration1-768x543.png 768w" sizes="(max-width: 1525px) 100vw, 1525px" />
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <div>
                                    <h2 style="font-size: 18px;">Work Seemlessly with On-Premise Data</h2>
                                    <p>Flow lets you securely connect to on-premises data and cloud based services, so you can make the most of the data you already have.</p>
                                </div>
                                <div>
                                    <h2 style="font-size: 18px;">Multi-Step Flows & Conditions</h2>
                                    <p>Turn repetitive tasks into multistep workflows. For example, with a few clicks capture tweets and add them as leads in Dynamics 365 Business Central or LS Central, subscribers in Microsoft Office 365 or Microsoft 365, and more.  Make decisions in your workflow, like running an action only when certain conditions are met.</p>
                                </div>
                                <div>
                                    <h2 style="font-size: 18px;">Secure</h2>
                                    <p>Prevent sensitive data from leaving your company using built-in or customized data prevention loss. Inherit the security from your Line Of Business and productivity applications.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="min-height: 520px;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 18px;">Right Data, Right Place at Right Time</h2>
                                <p>The success of your implementation relies on having the right data, in the right place at the right time. With multiple integration methods at our disposal, our team of integration consultants will architect a solution that ensures the productivity of your business flows at a rapid rate and that your sales team has all the insights possible to close the deals. No matter what product you need to integrate, whether on-premise or cloud we have the capabilities for you. Our most common integrations are between Dynamics 365 Business Central or LS Central.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="631" height="427" src="assets/images/integration.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/integration.png 631w, assets/images/integration-300x203.png 300w" sizes="(max-width: 631px) 100vw, 631px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 18px;">Common Data Service</h2>
                            <div>
                                <p>The Microsoft Common Data Service is your place to exchange information both for Dynamics 365 Business Central or LS Central as well as other platforms. Having the right organizational data stores that allow you a single view of all your data elements in a service that supports easy interchange of data between applications will allow you to expand your offering rapidly. From website integration, mobile applications, productivity applications and line of business applications – you can have a single source of the truth for all data exchanged. Use the common data service to support your business growth.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; DYNAMICS 365</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
        </div>
    </div>
</div>
