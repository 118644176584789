import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AppService } from "./app.service";

import * as myGlobals from "./globals";
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'enovasions';
  private readonly notifier: NotifierService;
  form_data:any = {};
  input:any = null;
  phoneInput:any = null;

  constructor(notifierService: NotifierService, private _appService: AppService) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    /** spinner starts **/
    myGlobals.setLoadingData(true);
    /** spinner ends **/
    setTimeout(() => myGlobals.setLoadingData(false), 10000);
    
    this.form_data = {
        Name: "",  Phone: "", Email: "", Company: "", Address: "", Message: ""
    };

    this.input = document.querySelector("#phoneid");
    this.phoneInput = (<any>window).intlTelInput(this.input, {
        preferredCountries: ["fj", "in"],
        hiddenInput : "full_phone",
        utilsScript: "../assets/jquery/utils.min.js"
    });
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  contactUsForm(form_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    let input = this.input;
    let phoneInput = this.phoneInput

    let extension = phoneInput.getSelectedCountryData().dialCode;
    let phoneNumber = phoneInput.getNumber();
    this.form_data.Phone = phoneNumber;
    
    if (this.form_data.Name == "") {
      notifyArray[notifyArray.length] =  "Name is required.";
      this.notifier.notify('error', 'Name is required.');                                       
    }

    if (this.form_data.Phone == "" || this.form_data.Phone == undefined) {
      notifyArray[notifyArray.length] =  "Phone number is required.";
      this.notifier.notify('error', 'Phone number is required.');         
    }

    if (this.form_data.Company == "") {
      notifyArray[notifyArray.length] =  "Company name is required.";
      this.notifier.notify('error', 'Company name is required.');
    }

    if (this.form_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.form_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.form_data.Address == "" || this.form_data.Address == undefined) {
      notifyArray[notifyArray.length] =  "Address is required.";
      this.notifier.notify('error', 'Address is required.');         
    } 

    if (this.form_data.Message == "" || this.form_data.Message == undefined) {
      notifyArray[notifyArray.length] =  "Message is required.";
      this.notifier.notify('error', 'Message is required.');         
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      /** spinner starts **/
      myGlobals.setLoadingData(true);
      
      let data = new FormData();
      data.append("form_data", JSON.stringify(this.form_data));
      this._appService.submitContactUsForm(data).subscribe((item: any) => this.setContactUsForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setContactUsForm(item: any) {
    /** spinner ends **/
    myGlobals.setLoadingData(false);
    
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your request has been submitted successfully.";
      this.notifier.notify('success', item.message); 
    } else {
        notifyArray[notifyArray.length] =  item.message;
        this.notifier.notify('error', item.message); 
    }
    this.form_data = {};

    $(function () {
      $('#contact-us').modal('toggle');
    });
  }

}
