import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'; 

declare let $: any;

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BlogsComponent implements OnInit {

  public msblogs:any = [];
  public lsretailblogs:any = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loadLsRetailBlogsData();
    this.loadMSBlogsData();

    setTimeout(() => $("#wrap-text > img").remove(), 1000);
    setTimeout(() => ​$("#wrap-text1 > p:last-child").remove(), 1000);​​​​​​​​​
  }

  loadLsRetailBlogsData() {
    let self = this; 
    this.http.get("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.lsretail.com%2Fblog%2Frss.xml")  
      .subscribe((res: any) => {  
        self.lsretailblogs = res.items;
    });
  }

  loadMSBlogsData() {
    let self = this; 
    this.http.get("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblogs.microsoft.com%2Ffeed%2F")  
      .subscribe((res: any) => {  
        self.msblogs = res.items;
    });
  }
}
