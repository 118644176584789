import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobileappdevelop',
  templateUrl: './mobileappdevelop.component.html',
  styleUrls: ['./mobileappdevelop.component.css']
})
export class MobileappdevelopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
