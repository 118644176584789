import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msoffice',
  templateUrl: './msoffice.component.html',
  styleUrls: ['./msoffice.component.css']
})
export class MsofficeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
