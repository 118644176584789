import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function() {
      $(".btn").click(function () {
        if ($(".collapse.show")) {
          $(".collapse").removeClass("show");
          $(".collapse").collapse("hide");
          $(".btn").find(".fa").removeClass("fa-minus").addClass("fa-plus");
        } else {
          $(".collapse").addClass("show");
          $(".collapse").collapse("show");
          $(".btn").find(".fa").removeClass("fa-plus").addClass("fa-minus");
        }
      });

      //Add a minus icon to the collapse element that is open by default
      $('.collapse.show').each(function(this: any) {
        $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
      });
          
      //Toggle plus/minus icon on show/hide of collapse element
      $('.collapse').on('shown.bs.collapse', function(this: any) {
        $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
      }).on('hidden.bs.collapse', function(this: any) {
        $(this).parent().find(".fa").removeClass("fa-minus").addClass("fa-plus");
      });     
    });
  }

}
