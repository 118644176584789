import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msoffice365',
  templateUrl: './msoffice365.component.html',
  styleUrls: ['./msoffice365.component.css']
})
export class Msoffice365Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
