<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Upgrade NAV to Business Central</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Upgrade NAV to Business Central</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 22px;">Are you ready to Upgrade from Dynamics NAV to Dynamics 365 Business Central?</h2>
                            <p>The launch of BC has posed this question to all the users of Navision as ERP what is the future?  Should they upgrade or stay on the current version?</p>
                            <p>Upgrading brings up thoughts of cost, pain, business disruption, and maybe even limited reward for all the effort. But it’s still something that one will have to evaluate as currently  NAV solution might be still meeting the needs now, however eventually support for the version will end, there will be more challenges around integrating with other applications and systems as they advance, and one can  fall behind the competition because they’re taking advantage of the latest technology has to offer.</p>
                            <p>There are five main areas of focus to take into consideration when researching the upgrade from Dynamics NAV to Dynamics 365 Business Central.</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <img width="720" height="384" src="assets/images/bc-everywhere.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/bc-everywhere.png 720w, assets/images/bc-everywhere-300x160.png 300w" sizes="(max-width: 720px) 100vw, 720px" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="margin-bottom: 40px; text-align: center;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">Our Offerings</h2>
                            </div>
                            <div class="boxcard">
                                <h2><b>Stability, Performance and Security</b></h2>
                                <div class="box-container">
                                    <p>Data security is top-of-mind for most IT professionals. The Microsoft Cloud is synonymous with security and stability. With over 3,500 cyber-security professionals on staff, Microsoft is an industry leader when it comes to protecting, detecting, and responding to cyber threats. With so many IT experts in your corner, your IT staff can become more productive without having to worry about managing servers and ever-changing security regulations.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <h2><b>Cost Effective</b></h2>
                                <div class="box-container">
                                    <p>Essentially, moving to the cloud is a financial shift that reallocates ERP spend from infrastructure, costly servers, and upgrades to licensing fees. In fact, transitioning from Dynamics NAV to Dynamics 365 Business Central is the final ‘upgrade’ you’ll ever have to pay for. Once you have implemented Dynamics 365, updates happen frequently, and at no cost, ensuring you’re always on the most recent version.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <h2><b>Integrated Modern Platform</b></h2>
                                <div class="box-container">
                                    <p>Dynamics 365 Business Central features an intuitive look and feel that will help reduce training and ramp-up time for users because it’s easy to use and similar to other Microsoft solutions. Additionally, Business Central can be accessed anywhere, at any time – giving users the flexibility to work no matter where they are. The deep integration with the Business Central Cloud solution and O365 is the best starting point on your journey to consider the move from NAV to Business Central Cloud creating best work from Home Solution.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard1">
                                <h2><b>Digital Transformation</b></h2>
                                <div class="box-container">
                                    <p>With Dynamics 365 Business Central, businesses can easily embrace the benefits of Machine Learning and Artificial Intelligence to become more efficient and reduce manual processes. Machine Learning can help with equipment monitoring, spam filtering, ad targeting, image detection, forecasting, and much more. Microsoft’s Power Platform, made up of Power BI, Flow, and Power Apps, gives you better data that is more accurate and timely to help you make better business decisions.</p>
                                </div>
                            </div>
                            <div class="boxcard1" style="margin-right: 0;">
                                <h2><b>Microsoft AppSource</b></h2>
                                <div class="box-container">
                                    <p>Microsoft AppSource helps business users find and try software-as-a-service (SaaS) apps from Microsoft and its partners. Microsoft AppSource apps include Web Apps and apps that are built on top of Microsoft Dynamics, Office 365, and PowerApps including Power BI.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <p style="font-size: 2em; line-height: 1.4;">Case study - Moving from Microsoft Dynamics NAV to Business Central</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <iframe width="749" height="421" src="https://www.youtube.com/embed/RknG4FGsBsA?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <iframe width="760" height="427" src="https://www.youtube.com/embed/nTl0_n7rnxI?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <p style="font-size: 2em; line-height: 1.4;">2020 Full Introduction to Dynamics 365 Business Central (44min) showcasing the integrated and unified nature of Dynamics 365, with Office 365 and the Power Platform</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <p>An industry specific assessment, completed by Enovasions experts, to help your company define and achieve your goals through the implementation and customization of Dynamics 365 Business Central.</p>
                            <p><strong>Agenda</strong></p>
                            <ul style="padding-left: 40px;">
                                <li>Meet with Enovasions experts to define your company’s enterprise goals, objectives, and opportunities to leverage new technical and functional capabilities within Dynamics 365 Business Central</li>
                                <li>Define scope by specifying processes and business functions to be covered by Dynamics 365 Business Central</li>
                                <li>Review of Enovasions’ proven BLUEPRINT methodology for successful implementation within your industry</li>
                                <li>Discuss potential resources, teams, roles, and responsibilities for technical tasks</li>
                                <li>Define rollout and risk migration strategy</li>
                                <li>Deployment preferences and options around cloud, hosted, and on premises—production/stage/QA/test</li>
                                <li>Users and licensing</li>
                            </ul>
                            <p><strong>Deliverables</strong></p>
                            <ul style="padding-left: 40px;">
                                <li>Estimation of the Time/Effort requirements to complete the upgrade project</li>
                                <li>Future-state application/integration map</li>
                                <li>Estimattion of hours and scope for any additional or new phase functionality not previously implemented; for example, implementing new Warehouse Management System</li>
                                <li>Recommended upgrade rollout plan and phasing</li>
                                <li>Recommended customization and ISV product migration or replacement strategy</li>
                                <li>Recommended data migration strategy and scope, including converting legacy Warehouse Management System warehouses and items to post Warehouse Management System</li>
                                <li>Recommended license mapping to new user roles </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ FREE ASSESSMENT</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
