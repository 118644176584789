<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Microsoft SharePoint</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Microsoft SharePoint</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Microsoft SharePoint Introduction</h2>
                                <p>Our customers see tangible business improvements when using Sharepoint, becoming more efficient and more effective at satisfying their customers.</p>
                                <p>SharePoint is a collaboration platform that dramatically improves the way businesses store, share, and leverage information; especially integrating it into automated processes and workflows both inside and outside their organisations. Microsoft has really upped its investment in SharePoint in the past couple of years and it now forms a fundamental part of their Office 365 and other cloud-based product offerings.</p>
                                <p>This makes it even easier to connect SharePoint workflows into Office applications as well as Enterprise tools like CRM and ERP. Coupled with the possibility of adding .NET into the mix, it’s really opened up access to much more cost-effective ways to improve internal efficiencies and external differentiators.</p>
                                <p>We’ve been working with various application development tools for over 24+ years now – typically as add-ons to CRM or ERP implementations, or in some cases fully standalone bespoke applications. The features of SharePoint are ‘coming of age’ and our Customers in these areas are seeing great downstream benefits.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <!-- <iframe style="height: 300px;" allowfullscreen title="youtube Video Player" src="https://www.youtube.com/watch?v=9xxGkrBOuQg&amp;feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe> -->
                                <iframe style="min-height: 480px;"  width="683" height="480" src="https://www.youtube.com/embed/9xxGkrBOuQg?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 30px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div>
                                <h2 style="font-size: 22px; text-align: center;">SharePoint Capabilities</h2>		
                            </div>
                            <div id="exTab1" class="container" style="padding-left: 0px; padding-right: 0px;">	
                                <ul id="tab1" class="nav nav-pills">
                                    <li class="active">
                                        <a  href="#1a" data-toggle="tab">Share</a>
                                    </li>
                                    <li>
                                        <a href="#2a" data-toggle="tab">Organise</a>
                                    </li>
                                    <li>
                                        <a href="#3a" data-toggle="tab">Discover</a>
                                    </li>
                                    <li>
                                        <a href="#4a" data-toggle="tab">Build</a>
                                    </li>
                                    <li>
                                        <a href="#5a" data-toggle="tab">Manage</a>
                                    </li>
                                </ul>
                                
                                <div id="tab1-content" class="tab-content clearfix">
                                    <div class="tab-pane active" id="1a">
                                        <p>Connect with employees across the enterprise – use SharePoint to engage with people, share ideas and reinvent the way you work together.</p>
                                        <ul style="padding-left: 40px;">
                                            <li><strong>OneDrive for Business</strong> – Keep your documents in sync, access and share them wherever you go.</li>
                                            <li><strong>Share Documents</strong> – A single place to store all your documents and collaborate with colleagues in real-time</li>
                                            <li><strong>Social feeds</strong> – Use feeds to stay on top of relevant content and collaborate with coworkers</li>
                                            <li><strong>Groups &amp; Communities</strong> – Engage in discussions with experts and find answers</li>
                                            <li><strong>Mobile –&nbsp;</strong>Anywhere access to people and your information</li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane" id="2a">
                                        <p>Whether working as a team or an individual, SharePoint helps you organise information, people and projects.</p>
                                        <ul style="padding-left: 40px;">
                                            <li><strong>Sites</strong> – Create a single location for your team to organise content, share ideas and manage a project</li>
                                            <li><strong>Task Management</strong> – Manage personal and team tasks right within SharePoint and integrate with familiar tools like Outlook and Project</li>
                                            <li><strong>Site Mailbox</strong> – Manage your project email and documents in one place</li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane" id="3a">
                                        <p>SharePoint makes it easy to find answers, discover insights and connect with experts.</p>
                                        <ul style="padding-left: 40px;">
                                            <li><strong>Find stuff</strong> – SharePoint search is like Bing for any information within your company</li>
                                            <li><strong>Your results</strong> – Personalised results based on your intent and past behaviour</li>
                                            <li><strong>Find people</strong> – Connect with the experts in your enterprise</li>
                                            <li><strong>Visualise Data</strong> – Share dashboards and interactive reports using Power View</li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane" id="4a">
                                        <p>Developers and web designers can create new experiences on SharePoint using familiar tools and internet standards.</p>
                                        <ul style="padding-left: 40px;">
                                            <li><strong>SharePoint App Store</strong> – Discover and download apps</li>
                                            <li><strong>Build&nbsp;Websites</strong> – Design and publish rich websites using familiar tools and internet standards</li>
                                            <li><strong>Adaptive Site experiences</strong> – Create a unique experience for every visitor to your website</li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane" id="5a">
                                        <p>SharePoint provides powerful controls that allow IT departments to manage cost, risk and their time.</p>
                                        <ul style="padding-left: 40px;">
                                            <li><strong>Simplified Upgrade</strong> – A multi-step upgrade process allows you to upgrade servers without impacting users</li>
                                            <li><strong>Optimise Performance</strong> – SharePoint servers are designed to use minimal resources yet deliver a snappy user experience</li>
                                            <li><strong>Manage risk</strong> – A single place to perform eDiscovery across sites, email and IM</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 22px; text-align: center;">SharePoint Online</h2>
                            <p>SharePoint Online delivers the powerful features of SharePoint without the associated overhead of managing the infrastructure on your own. Flexible management options ensure that you still retain the control you need to meet the compliance requirements of your organisation. You can purchase SharePoint in the cloud as a standalone offering or as part of an Office 365 suite, where you can also get access to Exchange, Microsoft Teams, the Office clients, and web apps.</p>
                            <ul style="padding-left: 40px;">
                                <li><strong>Cross-device availability</strong> – Easily access and interact with your SharePoint sites&nbsp;wherever you go, using the SharePoint mobile apps available across various devices.</li>
                                <li><strong>Easy to manage</strong> – You can get set up in virtually no time! The powerful admin console allows your organisation to easily manage capabilities, policies, and security for all the content and features within SharePoint. Automated maintenance of the servers ensures that you are always up to date with the latest features with minimal downtime.</li>
                                <li><strong>Enterprise-grade reliability and standards</strong> – Safeguard your data by hosting it in geographically distributed data centres with premier disaster recovery capabilities and a team of experts monitoring the servers around the clock.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; MICROSOFT SHAREPOINT</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
