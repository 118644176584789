import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  constructor(private http: HttpClient) { }

//   private baseUrl = 'http://enovasions';

  private baseUrl = this.getUrl() + "/enovasions_api";
  getUrl(): any { return ""; }

  submitForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitFormData&type=positionService";
      return this.http.post(url, form_data);
  }

  submitContactUsForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitContactData&type=homeService";
      return this.http.post(url, form_data);
  }

  submitMainContactUsForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitMainContactData&type=contactService";
      return this.http.post(url, form_data);
  }

  submitEnquiryForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitEnquiryData&type=enquiryService";
      return this.http.post(url, form_data);
  }

  submitMessageForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitMessageData&type=messageService";
      return this.http.post(url, form_data);
  }

  submitQuickEnquiryForm(form_data: any): Observable<any> {
      let url = this.baseUrl + "/webService.php?action=submitQuickEnquiryData&type=quickEnquiryService";
      return this.http.post(url, form_data);
  }

}
