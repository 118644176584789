<footer id="footer" class="site-footer">
	<!-- <a href="#" id="toTop" class="toTop"><i class="fa fa-angle-up"></i></a> -->
	<div  class="footer-sidebar">
		<div class="container">
			<div class="row">            
				<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
					<div class="widget theme_widget_about">
						<h4 class="widget-title">About us</h4> 

						<div class="site-logo">
							<div class="header-logo">
								<a routerLink="/home"><img src="assets/images/Enovasions-Logo02.png" alt="Enovasions"></a>
							</div>
							<div class="site-description">
								<p class="site-tagline">Welcome to Enovasions Pte Limited</p>
							</div>
						</div>
						
						<div class="site-about">The company with over 24 years experience of delivering best consulting services for business needs.</div>
						
						<div class="contact-info">
							<ul class=" info-list">
								<li class="address-wrapper">Address: <b>Level 6, FNPF Place, 343 Victoria Parade, GPO Box 855, Suva, Fiji</b></li>                                    
								<li class="phone-wrapper">Phone: <b>+679 330 4121</b></li>
								<li class="phone-wrapper">Email: <b>helpdesk@enovasions.com.fj</b></li>
							</ul> 
						</div>
					</div>            
				</div>
				
				<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
					<div class="widget theme_widget_recent_posts">            
						<h4 class="widget-title">Recent Posts</h4>            
						<ul>
							<li *ngFor="let retailBlogs of _blog.lsretailblogs; let cnt = index">
								<div *ngIf="cnt<=2">
									<a href="{{retailBlogs.link}}" title="{{retailBlogs.title}}" class="entry-thumbnail" target="_blank">
										<!-- <img width="150" height="150" src="assets/images/apple-imac-ipad-workplace-38568-150x150.jpg" class="attachment-thumbnail size-thumbnail wp-post-image" alt="" /> -->
										<img width="150" height="150" src="{{retailBlogs.thumbnail}}" class="attachment-thumbnail size-thumbnail" alt="" sizes="(max-width: 150px) 100vw, 150px" />
									</a>
									<div class="entry-content">                        
										<a href="{{retailBlogs.link}}" target="_blank">{{retailBlogs.title}}</a>
									</div>
									<div class="clearfix"></div>
								</div>
							</li>
								
							<!-- <li>
								<a href="#" title="Clients are fuel for financial business" class="entry-thumbnail">
									<img width="150" height="150" src="assets/images/people-woman-coffee-meeting-150x150.jpg" class="attachment-thumbnail size-thumbnail" alt="" srcset="assets/images/people-woman-coffee-meeting-150x150.jpg 150w, assets/images/people-woman-coffee-meeting-180x180.jpg 180w, assets/images/people-woman-coffee-meeting-300x300.jpg 300w, assets/images/people-woman-coffee-meeting-600x600.jpg 600w, assets/images/people-woman-coffee-meeting-170x170.jpg 170w" sizes="(max-width: 150px) 100vw, 150px">
								</a>
								<div class="entry-content">                        
									<a href="#">Clients are fuel for financial business</a>
								</div>
								<div class="clearfix"></div>
							</li>
								
							<li>
								<a href="#" title="Offer trading services under your brand" class="entry-thumbnail">
									<img width="150" height="150" src="assets/images/apple-iphone-books-desk-150x150.jpg" class="attachment-thumbnail size-thumbnail" alt="" srcset="assets/images/apple-iphone-books-desk-150x150.jpg 150w, assets/images/apple-iphone-books-desk-180x180.jpg 180w, assets/images/apple-iphone-books-desk-300x300.jpg 300w, assets/images/apple-iphone-books-desk-600x600.jpg 600w, assets/images/apple-iphone-books-desk-170x170.jpg 170w" sizes="(max-width: 150px) 100vw, 150px">
								</a>
								<div class="entry-content">                        
									<a href="#">Offer trading services under your brand</a>
								</div>
								<div class="clearfix"></div>
							</li> -->
						</ul>
					</div>
				</div>
				
				<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
					<div class="widget widget_authors">        
						<h4 class="widget-title">QUICK ENQUIRY</h4>        
						<div class="contact-form row">
							<div class="form-field col-xs-12">
								<input type="text" class="form-control input-text" placeholder="Name" [(ngModel)]="enquiry_data.Name" (keypress)="blockSpecialChar($event)">
							</div>
							<div class="form-field col-xs-12">
								<input type="text" class="form-control input-text" placeholder="Offical Email" [(ngModel)]="enquiry_data.Email" (blur)="validateEmail($event)">
							</div>
							<div class="form-field col-xs-12">
								<input type="tel" id="phone" class="form-control input-text" placeholder="Phone" [(ngModel)]="enquiry_data.Phone" (keydown)="isNumberKey($event)">
							</div>
							<div class="form-field col-xs-12">
								<input type="text" class="form-control input-text" placeholder="Company Name" [(ngModel)]="enquiry_data.Company">
							</div>
							<div class="form-field col-xs-12">
								<input type="text" class="form-control input-text" placeholder="Address" [(ngModel)]="enquiry_data.Address">
							</div>
							<div class="form-field col-xs-12">
								<textarea class="form-control input-text" placeholder="Message" [(ngModel)]="enquiry_data.Message"></textarea>
							</div>
							<div class="form-field col-xs-12">
								<button (click)="quickEnquiryForm(enquiry_data)" style="width: 100%;" class="btn btn-primary form-contuct-submit">Send</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>		
	
	<div class="footer-inner">
		<div class="container">
			<!-- <p class="social-profile type1 pull-right">
				<a href="#" class="button-facebook" title="Facebook" target="_blank"><i class="fa fa-facebook-square"></i></a>
				<a href="#" class="button-twitter" title="Twitter" target="_blank"><i class="fa fa-twitter-square"></i></a>
				<a href="#" class="button-google" title="Google +" target="_blank"><i class="fa fa-google-plus-square"></i></a>
				<a href="#" class="button-instagram" title="Instagram" target="_blank"><i class="fa fa-instagram"></i></a>
				<a href="#" class="button-youtube" title="Youtube" target="_blank"><i class="fa fa-youtube-square"></i></a>
			</p> -->

			<p class="copyright">
				<span class="copyright-date">&copy; Copyright 2021 </span>
				<a href="http://enovasions.com/" title="Enovasions" target="_blank">Enovasions Pte Limited</a>
				&#8226; Designed by 
				<a href="http://enovasions.com/" rel="nofollow" title="Enovasions">Enovasions Pte Limited</a>
			</p><!-- .copyright -->
		</div>
	</div>
</footer>