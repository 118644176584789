<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">HRMS &amp; Payroll</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">HRMS &amp; Payroll</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">HRMS &amp; Payroll Solution for Microsoft Dynamics 365 Business Central &amp; Microsoft Dynamics NAV</h2>
                                <p>Why would HRMS &amp; Payroll not be a part of your organisation's core administration application?</p>
                                <p>HRMS &amp; Payroll for Dynamics 365 Business Central is a Fiji Revenue &amp; Customs Service, deployed within Dynamics 365 Business Central, for organisations seeking an all in one Dynamics 365 Business Central administration solution. Designed for Fijian business with FNPF &amp; FRCS requirements in mind, HRMS &amp; Payroll for Dynamics 365 Business Central internalises HRMS &amp; Payroll within Business Central’s Human Resource module, seamlessly integrating core Business Central functions with comprehensive HRMS &amp; Payroll functionality.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <iframe style="height: 300px;" allowfullscreen title="youtube Video Player" src="https://www.youtube.com/embed/8W_cwdIrWF4?autoplay=1&mute=1"></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="text-align: center;">
                                <h2 style="font-size: 22px;">Enovasions HRMS & Payroll Solution for Microsoft Dynamics 365 <br/>Business Central &amp; Microsoft Dynamics NAV</h2>
                                <p>Our HRMS &amp; Payroll Solution for Microsoft Dynamics 365 Business Central internalises all key payroll functions within Business Central and connects payroll content to financials, G/L Chart, Bank Accounts, Single Touch Payroll & Super Stream requirements.</p>
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_1" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone" style="overflow: hidden;">
                            <div style="margin-bottom: 40px;">
                                <h2 style="text-align: center; font-size: 22px;">HRMS &amp; Payroll for your global needs</h2>
                            </div>
                            <div>
                                <div class="boxcard">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-globe fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>LOCALIZED FOR YOUR REGION</b></h4>
                                        <p>Capabilities to be localized as per the country needs. Maintains all relevant statutory information with multi lingual support.</p>
                                    </div>
                                </div>
                                <div class="boxcard" style="margin-right: 0;">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-money fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>HASSLE FREE PAYROLL</b></h4>
                                        <p>Improve control and reduce business risk with a fully Integrated payroll solution designed Microsoft D365 Platform.</p>
                                    </div>
                                </div>
                                <!-- <div class="boxcard" style="margin-right: 0;">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>YOU DESIGN YOUR PAYROLL</b></h4>
                                        <p>Rule based flexible Steeples Expression Evaluation Engine (EEE) designed to create user defined pay element formulas and expressions.</p>  
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_2" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone" style="overflow: hidden; padding-top: 0;">
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>GAIN CONTROL OVER DATA</b></h4>
                                    <p>Gain control over data for increased accountability and comply faster and more easily with statutory government regulations. Improve data integrity for greater accuracy.</p>
                                </div>
                            </div>
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-users fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>ABSENCE MANAGEMENT HANDLER</b></h4>
                                    <p>Enjoy the various enhancements made in features like Absence Management and Self Service, which are value ads to the solution.</p>
                                </div>
                            </div>
                            <div class="boxcard1" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-bell-o fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>CONFIGURE AND RECEIVE ALERTS</b></h4>
                                    <p>Receive alerts on configured important documents that impact the day to day workings of company and employee.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div>
                                <h2 style="text-align: center;">Features</h2>		
                            </div>
                            <div id="exTab1" class="container" style="padding-left: 0px; padding-right: 0px;">	
                                <ul id="tab1" class="nav nav-pills">
                                    <li class="active">
                                        <a  href="#1a" data-toggle="tab">Key Features</a>
                                    </li>
                                    <li>
                                        <a href="#2a" data-toggle="tab">Employee Self Service</a>
                                    </li>
                                    <li>
                                        <a href="#3a" data-toggle="tab">Leave Policy</a>
                                    </li>
                                    <li>
                                        <a href="#4a" data-toggle="tab">Leave Management</a>
                                    </li>
                                </ul>
                                
                                <div id="tab1-content" class="tab-content clearfix">
                                    <div class="tab-pane active" id="1a" style="min-height: 860px;">
                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                            <ul style="padding-left: 20px;">
                                                <li>Salary Setup, Monthly deduction, Leave Entry.</li>
                                                <li>Set paid days, Salary Calculation, Salary Disbursement.</li>
                                                <li>Over Time Calculation.</li>                                                
                                                <li>Reports: Salary Register, Salary Sheet, Salary Advice For Bank, Performance Process, Yearly Salary Employee wise (For Tax Calculation).</li>                                                
                                                <li>Multiple employee bank account.</li>
                                                <li>Multiple pay cycles (i.e. weekly, fortnightly etc.).</li>
                                                <li>Timesheet entry for job costing and payroll processing.</li>
                                                <li>Entitlements and Allowances.</li>
                                                <li>Deductions.</li>
                                                <li>Payroll Tax.</li>
                                                <li>Termination Pays.</li>
                                                <li>EFT Lodgments based on Fijian formats.</li>
                                                <li>Emailing of Pay slips.</li>
                                                <li>SuperStream compatible.</li>
                                                <li>Employee self service capability.</li>
                                                <li>Business Central Security.</li>
                                                <li>Comprehensive Reporting and Dashboards.</li>
                                                <li>Integrate with a variety of third-party time and attendance tools (subject to criteria).</li>
                                            </ul>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                            <img width="550" height="350" src="assets/images/HRMS-Payroll-Image1.png" class="attachment-full size-full center-block" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="2a" style="min-height: 460px;">
                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                            <img width="550" height="350" src="assets/images/HRMS-Payroll-Image2.png" class="attachment-full size-full center-block" alt="" loading="lazy" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                            <ul style="padding-left: 20px;">
                                                <li>Individual Employee Login with Password protection and change of Password on regular intervals for enhanced security. Display of detailed Employee Personal Profile.</li>
                                                <li>Display of Leave Balances with drop down to ledger level view.</li>
                                                <li>Display of Loan Balances with drop down to ledger level view, Repayment Schedule &amp; available respective balances of various Loans.</li>
                                                <li>Display of Reimbursement details with ledger level view, vouchers given &amp; available respective balances of various Reimbursement heads.</li>
                                                <li>Display of Current CTC, Current &amp; Historic Pay slips along with Summarized pay slip for from &amp; to period.</li>
                                                <li>Submit Investment Declarations online.</li>
                                                <li>Display of IT Projection report on Investment declarations.</li>
                                                <li>Display Company Rules &amp; Notice available in ESSM module.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="3a" style="min-height: 460px;">
                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                            <ul style="padding-left: 20px;">
                                                <li>Parameters can be set for each type of Leave Policy as per company’s requirement.</li>
                                                <li>Different types of Policies can be set for Different types of Leaves.</li>
                                                <li>Different types of leaves can be applies online like CL, SL, PL, LWP &amp; user Defines Leaves.</li>
                                                <li>Employees can view Leave balances while applying for the leave types.</li>
                                                <li>Approver’s Level can be set as per company’s requirement &amp; N number of approver’s level is possible.</li>
                                                <li>In case of zero balance of leaves Blocking/Allowing facility is available.</li>
                                                <li>Minimum/ Maximum number of Leaves in an instance can be controlled.</li>
                                            </ul>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                            <img width="550" height="350" src="assets/images/HRMS-Payroll-Image3.png" class="attachment-full size-full center-block" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="4a" style="min-height: 820px;">
                                       <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                            <img width="550" height="350" src="assets/images/HRMS-Payroll-Image4.png" class="attachment-full size-full center-block" alt="" loading="lazy" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                            <ul style="padding-left: 20px;">
                                                <li>Yearly &amp; Monthly Leave ledgers are available for cross verification of leaves.</li>
                                                <li>Email intimation facility while submit, accepted, rejected &amp; cancelled leaves applications.</li>
                                                <li>Comprehensive view on various information of an employee to Approver.</li>
                                                <li>Leaves can also be applied for fraction of days.</li>
                                                <li>Tracking of leaves applied, approved, rejected &amp; cancelled is available.</li>
                                                <li>Sorting of Leaves on basis of dates of application.</li>
                                                <li>Auto posting of approved leaves in attendance module for salary processing.</li>
                                                <li>Multi Shift Management.</li>
                                                <li>Different Parameters could be set for each shift.</li> 
                                                <li>Set minimum working hours for half day, extra working rules, late coming and early go policy.</li>
                                                <li>Get daily attendance reports for individual employees, department wise and locations wise.</li>
                                                <li>Employee can view his daily attendance in ESS Module.</li>
                                                <li>Automated Overtime Calculation.</li>
                                                <li>Grace Periods for Shift Start/End Times.</li>
                                                <li>Overtime Management.</li>
                                                <li>Different kind of Reports.</li>
                                                <li>Multi location Holiday list, define Optional Holidays.</li>
                                                <li>Graphical Attendance Views on daily, weekly, monthly, Yearly basis.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; DYNAMICS NAV</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
