<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Microsoft Azure</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Microsoft Azure</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 22px;">Microsoft Azure – The Cloud for Business</h2>
                            <p>Microsoft Azure is a growing collection of integrated cloud services—analytics, computing, database, mobile, networking, storage, and web—for moving faster, achieving more, and saving money. Here’s what you can do with Azure.</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Dynamics on Azure</h2>
                                <p>Make smarter decisions, redesign business processes faster and fuel business growth using the cloud-based enterprise resource planning (ERP) solution built for and on, Azure—bringing together ERP, business intelligence, infrastructure, compute and database services.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="328" height="191" src="assets/images/dynamics.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/dynamics.png 328w, assets/images/dynamics-300x175.png 300w" sizes="(max-width: 328px) 100vw, 328px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px; margin: 50px 0;">
                                <img width="273" height="166" src="assets/images/davops.png" class="attachment-full size-full center-block" alt="" loading="lazy" />
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <h2 style="font-size: 22px;">DevOps</h2>
                                <p>DevOps brings together people, processes and products and enables continuous delivery of value to your end users. Azure makes DevOps easy, fast and cost-effective with the resources and open source tools you need to meet your business objectives.</p>
                                <p>Today’s markets move fast—faster than traditional development methods. A modern DevOps process in the cloud lets you release and iterate quickly, even several times a day. And getting to market first can be the advantage which makes you a market leader, instead of leaving you to play catch up.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Business Intelligence</h2>
                                <p>Transform your company’s data to support informed decision making. Gain deeper insight into your data to stay in the know and spot trends as they happen. With modern business intelligence (BI) solutions and tools, your entire organisation can understand and quickly act on data.</p><p>Better integrate with existing systems by choosing a feature-rich, enterprise-ready BI platform. Maximise resources, monitor access to data and assets, help ensure security and compliance—and deliver a business intelligence solution designed for the needs of your organisation.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="696" height="460" src="assets/images/business.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/business.png 696w, assets/images/business-300x198.png 300w" sizes="(max-width: 696px) 100vw, 696px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <img width="450" height="253" src="assets/images/internet-e1528351018357.jpg" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/internet-e1528351018357.jpg 450w, assets/images/internet-e1528351018357-300x169.jpg 300w" sizes="(max-width: 450px) 100vw, 450px" />	
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <h2 style="font-size: 22px;">Internet of Things</h2>
                                <p>Create the Internet of Your Things by connecting your devices, assets and sensors to collect untapped data. Improve performance and reduce costs with remote monitoring. Predict equipment failures before they happen with predictive maintenance.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">Backup and Archive</h2>
                                <p>Protect your data and applications no matter where they reside to avoid costly business interruptions or to meet compliance requirements. Securely extend your on-premises backup storage and data archiving solutions to the cloud—reducing cost and complexity, while achieving efficiency and scalability.</p>
                                <p><strong>Increase capacity, reduce costs</strong></p>
                                <p>Keep pace with the exponential growth of your enterprise data while lowering deployment and management costs. With a low-cost, massively-scalable, tiered backup storage solution in the cloud, you will reduce forecasting risks while transforming capital expenditure commitments to a pay-as-you-go cloud model.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px; margin: 50px 0;">
                                <img width="898" height="400" src="assets/images/backup.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/backup.png 898w, assets/images/backup-300x134.png 300w, assets/images/backup-768x342.png 768w" sizes="(max-width: 898px) 100vw, 898px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <img width="1280" height="720" src="assets/images/desaster.jpg" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/desaster.jpg 1280w, assets/images/desaster-300x169.jpg 300w, assets/images/desaster-1024x576.jpg 1024w, assets/images/desaster-768x432.jpg 768w" sizes="(max-width: 1280px) 100vw, 1280px" />
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <h2 style="font-size: 22px;">Disaster Recovery</h2>
                                <p>Even a minor outage can put you at a competitive disadvantage. So, be ready with a business continuity plan that includes disaster recovery for all your major IT systems—without the expense of secondary infrastructure.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; MICROSOFT AZURE</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
