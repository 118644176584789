<header id="header" class="main-header">
	<div class="top-header">
		<div class="container">
			<div class="top-menu">
				<ul id="menu-top-menu" class="menu">
					<li id="menu-item-2276" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2276">
						<a routerLink="/contactus">Contact Us</a>
					</li>
				</ul>
				<div class="clearfix"></div>
			</div>
					
			<!-- <div class="social-profile type1">
				<a href="#" class="button-facebook" title="Facebook" target="_blank"><i class="fa fa-facebook-square"></i></a>
				<a href="#" class="button-twitter" title="Twitter" target="_blank"><i class="fa fa-twitter-square"></i></a>
				<a href="#" class="button-google" title="Google +" target="_blank"><i class="fa fa-google-plus-square"></i></a>
				<a href="#" class="button-instagram" title="Instagram" target="_blank"><i class="fa fa-instagram"></i></a>
				<a href="#" class="button-youtube" title="Youtube" target="_blank"><i class="fa fa-youtube-square"></i></a>
			</div> -->
			
			<div class="contact-info ">
				<ul class=" info-list">
					<li class="address-wrapper"><b><span style="color: black">Address:</span> Level 6, FNPF Place, 343 Victoria Parade, GPO Box 855, Suva, Fiji</b></li>
					<li class="phone-wrapper"><b><span style="color: black">Phone:</span> +679 330 4121</b></li>
				</ul>
				<div class="clearfix"></div>
			</div>
		</div>
	</div>
		
	<div class="site-header" data-sticky-menu="off">
		<div class="container">
			<div class="site-logo">
				<a class="home-link" routerLink="/home" title="Enovasions" rel="home">
					<div class="header-logo">
						<img src="assets/images/Enovasions-Logo02.png" alt="Enovasions">
					</div>
				</a>
			</div>
			
			<button class="menu-toggle" aria-controls="main-menu" aria-expanded="false">
				<span class="menu-show">Menu</span>
				<span class="menu-close">Close</span>
			</button>

			<div id="navbar" class="navbar">
				<nav id="site-navigation" class="main-navigation">
					<div class="menu-primary-menu-container">
						<ul id="main-menu" class="sf-menu">
							<!-- <li class="homelink">
								<a routerLink="/home">Home</a>
							</li> -->
							<li>
								<a routerLink="/retail-industry">Industries</a>
								<button (click)="dropdownToggle('industry-toggle')" id="#industry-toggle" class="industry-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
								<ul id="industry-toggle" class="sub-menu">
									<li>
										<a routerLink="/retail-industry">Retail</a>
									</li>
									<li>
										<a routerLink="/hospitality-industry">Hospitality</a>
									</li>
									<li>
										<a routerLink="/ecommerce-industry">E-Commerce</a>
									</li>
									<li>
										<a routerLink="/logistics-industry">Logistics</a>
									</li>
									<li>
										<a routerLink="/food-and-beverage-industry">Manufacturing</a>
										<button (click)="dropdownToggle1('manufacturing-toggle')" id="#manufacturing-toggle" class="manufacturing-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
										<ul id="manufacturing-toggle" class="sub-menu">
											<li>
												<a routerLink="/food-and-beverage-industry">Food &amp; Beverages</a>
											</li>
											<li>
												<a routerLink="/steel-and-metals-industry">Steel &amp; Metals</a>
											</li>
										</ul>
									</li>
									<!-- <li>
										<a routerLink="/banking-industry">Banking &amp; Financial Institutes</a>
									</li> -->
								</ul>
							</li>

							<li>
								<a routerLink="/erp-services">Services</a>
								<button (click)="dropdownToggle('service-toggle')" id="#service-toggle" class="service-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
								<ul id="service-toggle" class="sub-menu">
									<li>
										<a routerLink="/erp-services">ERP</a>
									</li>
									<!-- <li>
										<a routerLink="/vms-services">VAT Monitoring Compliance (VMS)</a>
									</li>
									<li>
										<a routerLink="/ms-office-services">Microsoft Office 365 Migration</a>
									</li> -->
									<li>
										<a routerLink="/business-central-services">Upgrade NAV To Bussiness Central</a>
									</li>
									<li>
										<a routerLink="/system-integration-services">System Integration</a>
									</li>
									<li>
										<a routerLink="/web-development-services">Apps &amp; Web Apps Developement </a>
									</li>
									<!-- <li>
										<a routerLink="/support-services">Supports &amp; Services</a>
									</li>
									<li>
										<a routerLink="/it-strategy-services">IT Strategy</a>
									</li>
									<li>
										<a routerLink="/it-security-services">IT Infrastructure Security</a>
									</li> -->
								</ul>
							</li>

							<li>
								<a routerLink="/business-central-solutions">Products</a>
								<button (click)="dropdownToggle('product-toggle')" id="#product-toggle" class="product-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
								<ul id="product-toggle" class="sub-menu">
									<li>
										<a routerLink="/business-central-solutions">Dynamics 365 Business Central</a>
									</li>
									<!-- <li>
										<a routerLink="/dynamics-nav-solutions">Microsoft Dynamics NAV</a>
									</li> -->
									<li>
										<a routerLink="/ls-nav-solutions">LS Retail / LS Central</a>
									</li>
									<li>
										<a routerLink="/ls-retail-hospitality-solutions">LS Retail Hospitality</a>
									</li>
									<li>
										<a routerLink="/microsoft-azure-solutions">Microsoft Azure</a>
									</li>
									<li>
										<a routerLink="/power-bi-solutions">Power BI</a>
									</li>
									<li>
										<a routerLink="/hrms-payroll-solutions">Human Resource Management &amp; Payroll</a>
									</li>
									<!-- <li>
										<a routerLink="/ms-office-365-solutions">Microsoft 365</a>
									</li> -->
									<li>
										<a routerLink="/ms-sharepoint-solutions">Microsoft Sharepoint</a>
									</li>
									<!-- <li>
										<a routerLink="/veritas-solutions">Veritas</a>
									</li>
									<li>
										<a routerLink="/trend-micro-solutions">Trend Micro</a>
									</li> -->
								</ul>
							</li>

							<li>
								<a routerLink="/digital-cloud-solutions">Digital Transformations</a>
								<button (click)="dropdownToggle('digital-toggle')" id="#digital-toggle" class="digital-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
								<ul id="digital-toggle" class="sub-menu">
									<li>
										<a routerLink="/digital-cloud-solutions">Cloud</a>
									</li>
									<!-- <li>
										<a routerLink="/digital-web-development-solutions">Web Application Developement</a>
									</li>
									<li>
										<a routerLink="/digital-mobile-development-solutions">Mobile Apps Developement</a>
									</li> -->
									<!-- <li>
										<a routerLink="/digital-chatbot-solutions">ChatBot / Livechat -AI Developement</a>
									</li> -->
									<!-- <li>
										<a routerLink="/hrms-payroll-online-solutions">HRMS &amp; Payroll Online</a>
									</li>
									<li>
										<a routerLink="/vms-online-solutions">VMS Online</a>
									</li> -->
								</ul>
							</li>

							<li>
								<a routerLink="/about-us">About</a>
								<button (click)="dropdownToggle('about-toggle')" id="#about-toggle" class="about-toggle dropdown-toggle" aria-expanded="false"><i class="fa fa-angle-down" aria-hidden="true"></i><span class="screen-reader-text">expand child menu</span></button>
								<ul id="about-toggle" class="sub-menu">
									<li>
										<a routerLink="/about-us">About Us</a>
									</li>
									<li>
										<a routerLink="/work-with-us">Work With Us</a>
									</li>
									<li>
										<a routerLink="/blogs">Blogs</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</header>