import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AppService } from "../app.service";

import * as myGlobals from "../globals";
declare let $: any;

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  private readonly notifier: NotifierService;
  form_data:any = {};

  constructor(notifierService: NotifierService, private _appService: AppService) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.form_data = {
        Name: "", Email: "", Subject: "", Message: ""
    };
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  mainContactUsForm(form_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    
    if (this.form_data.Name == "") {
      notifyArray[notifyArray.length] =  "Name is required.";
      this.notifier.notify('error', 'Name is required.');                                       
    }

    if (this.form_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.form_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.form_data.Subject == "" || this.form_data.Subject == undefined) {
      notifyArray[notifyArray.length] =  "Subject is required.";
      this.notifier.notify('error', 'Subject is required.');         
    } 

    if (this.form_data.Message == "" || this.form_data.Message == undefined) {
      notifyArray[notifyArray.length] =  "Message is required.";
      this.notifier.notify('error', 'Message is required.');         
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      myGlobals.setLoadingData(true);
      let data = new FormData();
      data.append("form_data", JSON.stringify(this.form_data));
      this._appService.submitMainContactUsForm(data).subscribe((item: any) => this.setMainContactUsForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setMainContactUsForm(item: any) {
    myGlobals.setLoadingData(false);
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your request has been submitted successfully.";
      this.notifier.notify('success', item.message); 
      // return;
    } else {
        notifyArray[notifyArray.length] =  item.message;
        this.notifier.notify('error', item.message); 
        // return;
    }
    this.form_data = {};
  }

}
