<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Cloud Solutions</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Cloud Solutions</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="margin-bottom: 40px; text-align: center;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">Our Offerings</h2>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-mobile fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Public Cloud</b></h4>
                                    <p>Highly-secure, shared service cloud with virtual segregation of compute and storage, and private Layer 2 virtual LANs providing unlimited capacity around the globe.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-cloud-download fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Enterprise Private Cloud</b></h4>
                                    <p>Flexible private cloud delivers fully segregated, dedicated compute, storage, and network resources without requiring capital expense, time, and skills.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-line-chart fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Cloud Services for Dynamics 365 Business Central or LS Central</b></h4>
                                    <p>Manage costs and provide critical scalability through Dynamics 365 Business Central or LS Central applications.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-mobile fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Cloud Backup</b></h4>
                                    <p>Integrated, managed backup service, offering backup and recovery for all workloads in the private, hosted private, and public clouds.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-cloud-download fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Managed Hosting</b></h4>
                                    <p>Dedicated infrastructure and application management services interconnected to our public and private cloud.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-line-chart fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Provider and Community Cloud</b></h4>
                                    <p>Dedicated infrastructure and application management services interconnected to our public and private cloud.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 style="font-size: 22px;">Cloud Service Models</h2>
                            <p>Organizations can benefit from ubiquitous computing and engage in effective communication and data exchange at all times. Enovasions helps clients get up to speed on cloud enablement essentials and devise a comprehensive cloud model according to their needs.</p>
                            <p>Clients can choose the cloud service most suitable to their organizational setup.</p>
                            <ul style="padding-left: 40px;">
                                <li>On premise</li>
                                <li>Infrastructure as a Service (IaaS): Can avail computing resources on a subscription basis</li>
                                <li>Platform as a Service (PaaS): Can leverage Enovasions’ cloud platform offerings to develop and run applications without investing in associated hardware and software</li>
                                <li>Software as a Service (SaaS): Can gain access to on-demand software on a pay-per-use or subscription basis</li>
                            </ul>
                            <p><strong>Features</strong></p>
                            <ul style="padding-left: 40px;">
                                <li>Pay-as-you-go, per-user licensing</li>
                                <li>Rapid provisioning of IT resources</li>
                                <li>Location and device independence</li>
                                <li>Centralization of infrastructure for ubiquitous access and multi-tenancy (sharing of resources)</li>
                                <li>Flexible scaling</li>
                                <li>Use of third-party products</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <h2 style="font-size: 22px;">Plan for Application Migration</h2>
                            <p>Enovasions’ Professional Services takes the drama out of migrating apps to IaaS in public clouds by helping you identify hidden risks and constraints that can lead to performance issues, unexpected delays, and unplanned costs.</p>
                            <ul style="padding-left: 40px;">
                                <li>Map application dependencies</li>
                                <li>Use quantitative analysis to predict post-migration performance</li>
                                <li>Offer recommendations to improve performance and reduce risk</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <h2 style="font-size: 22px;">Deployment Methodology</h2>
                            <img width="967" height="495" src="assets/images/deployement-e1524201107711.png" class="attachment-full size-full" alt="" loading="lazy" srcset="assets/images/deployement-e1524201107711.png 967w, assets/images/deployement-e1524201107711-300x154.png 300w, assets/images/deployement-e1524201107711-768x393.png 768w" sizes="(max-width: 967px) 100vw, 967px" />
                        </div>
                    </div>

                    <div id="phases" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden; padding-top: 0;">
                            <h2 style="font-size: 22px;">THE 6.5 PHASES OF BEST AZURE PRACTICES</h2>
                            <ul>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Business Understanding – Understand the sensitivity of the application/ system (dev/ prod) moving to azure.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Design VNET – Define virtual networks and subnets for hosted services to reside in.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Deploy VM’s – Deploy Virtual Machine(s). If AD is desired deploy at this stage so remaining VMs can start domain joined.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Customize – Use RDP to customize the new persistent VM(s) by installing software, configuring roles etc.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Local Dev/Test – Build and test locally using the emulator. Testing live can be achieved by using public endpoints or VPN connectivity.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Deploy Service – Specify instance count, virtual network settings and other configuration details. Deploy to a separate hosted service.</span>
                                </li>
                                <li>
                                    <span style="padding-right: 20px;"><i aria-hidden="true" class="fa fa-check"></i></span>
                                    <span>Make Production Ready – If previously opened, close public endpoints to lock down service.</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ CLOUD SERVICES</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
