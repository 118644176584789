<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Contact Us</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Contact Us</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div>
                            <h2>CONTACT INFO</h2>
                        </div>
                        
                        <div>
                            <p><strong>Enovasions Pte Limited</strong><br />Level 6, FNPF Place, <br/>343 Victoria Parade, <br/>GPO Box 855, <br/>Suva, Fiji</p>
                            <p>Phone: +679 330 4121 <br /> E-mail: helpdesk@enovasions.com.fj</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div>
                            <h2>MESSAGE FORM</h2>
                        </div>
                        
                        <div>
                            <div role="form" class="wpcf7" id="wpcf7-f2274-p1820-o1" lang="en-US" dir="ltr">
                                <div class="screen-reader-response"></div>
                                <!-- <form action="#" method="post" class="wpcf7-form" novalidate="novalidate"> -->
                                    <p>Your Name<br />
                                        <span class="wpcf7-form-control-wrap your-name">
                                            <input type="text" [(ngModel)]="form_data.Name" (keypress)="blockSpecialChar($event)" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" />
                                        </span> 
                                    </p>
                                    
                                    <p>Your Email<br />
                                        <span class="wpcf7-form-control-wrap your-email">
                                            <input type="text" [(ngModel)]="form_data.Email" (blur)="validateEmail($event)" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" />
                                        </span>
                                    </p>
                                    
                                    <p>Subject<br />
                                        <span class="wpcf7-form-control-wrap your-subject">
                                            <input type="text" [(ngModel)]="form_data.Subject" class="wpcf7-form-control wpcf7-text" />
                                        </span>
                                    </p>
                                    
                                    <p>Your Message<br />
                                        <span class="wpcf7-form-control-wrap your-message">
                                            <textarea [(ngModel)]="form_data.Message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea"></textarea>
                                        </span>
                                    </p>
                                    
                                    <p><button (click)="mainContactUsForm(form_data)" class="btn btn-primary form-contuct-submit">Send</button></p>
                                <!-- </form> -->
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
