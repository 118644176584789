<div class="wrapper front-page">
  <loading [visible]="loadingData"></loading>
  <app-header></app-header>
  <notifier-container></notifier-container>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<!-- The Modal Login -->
<div class="modal fade" data-backdrop="static" id="contact-us">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title text-center" style="color: #AB987A;">PLEASE FILL THE FORM</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="contact-form row">
            <div class="form-field col-lg-6" style="padding-right: 5px;">
                <input type="text" class="form-control input-text" placeholder="Name*" [(ngModel)]="form_data.Name" (keypress)="blockSpecialChar($event)">
            </div>
            <div class="form-field col-lg-6" style="padding-left: 5px;">
                <input type="tel" id="phoneid" class="form-control input-text" placeholder="Phone*" [(ngModel)]="form_data.Phone" (keydown)="isNumberKey($event)">
            </div>
            <div class="form-field col-lg-6" style="padding-right: 5px;">
                <input type="text" class="form-control input-text" placeholder="Offical Email*" [(ngModel)]="form_data.Email" (blur)="validateEmail($event)">
            </div>
            <div class="form-field col-lg-6" style="padding-left: 5px;">
                <input type="text" class="form-control input-text" placeholder="Company*" [(ngModel)]="form_data.Company">
            </div>
            <div class="form-field col-lg-12">
                <textarea class="form-control input-text" placeholder="Address*" [(ngModel)]="form_data.Address"></textarea>
            </div>
            <div class="form-field col-lg-12">
                <textarea class="form-control input-text" placeholder="Message*" [(ngModel)]="form_data.Message"></textarea>
            </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button (click)="contactUsForm(form_data)" class="btn btn-primary form-contuct-submit">Send</button>
      </div>

    </div>
  </div>
</div>
