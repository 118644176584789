<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Apps &amp; Web Apps Developement</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Apps &amp; Web Apps Developement</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 18px; text-align: center;">Quick Enquiry Form</h2>
                                <div class="contact-form row">
                                    <div class="form-field col-lg-6">
                                        <input type="text" class="form-control input-text" placeholder="Name" [(ngModel)]="query_data.Name" (keypress)="blockSpecialChar($event)">
                                    </div>
                                    <div class="form-field col-lg-6 ">
                                        <input type="text" class="form-control input-text" placeholder="Company" [(ngModel)]="query_data.Company">
                                    </div>
                                    <div class="form-field col-lg-6 ">
                                        <input type="tel" id="webphone" class="form-control input-text" placeholder="Phone" [(ngModel)]="query_data.Phone" (keydown)="isNumberKey($event)">
                                    </div>
                                    <div class="form-field col-lg-6 ">
                                        <input type="text" class="form-control input-text" placeholder="Offical Email" [(ngModel)]="query_data.Email" (blur)="validateEmail($event)">
                                    </div>
                                    <div class="form-field col-lg-6 ">
                                        <input type="text" class="form-control input-text" placeholder="App Required For" [(ngModel)]="query_data.Required">
                                    </div>
                                    <div class="form-field col-lg-6 ">
                                        <textarea class="form-control input-text" placeholder="Your Query in Detail" [(ngModel)]="query_data.Query"></textarea>
                                    </div>
                                    <div class="form-field col-lg-12">
                                        <button style="width: 100%;" (click)="queryEnquiryForm(query_data)" class="btn btn-primary theme-contuct-submit">Send</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px; text-align: center;">
                                <h2 style="font-size: 18px;">Evolve With Modern Applications</h2>
                                <p>At Enovasions, we can build and deploy customized business applications that make the most of the cloud, supporting integration with your existing backend systems. Enable more business innovation by modernizing your legacy applications and platform so you can connect with modern applications and mobile devices — reaching more customers, increasing margins and reducing operational costs.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="min-height: 450px;">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 18px;">The Perfect Mix</h2>
                                <p>Our team of developers and designers create the perfect mix of art and science to help bring your app to life. Whether it’s helping you develop apps for different platforms or creating smart strategies that deliver in digital – we’re ready to help make your business shine.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <img width="1024" height="585" src="assets/images/the-perfect-mix-1024x585-1.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/the-perfect-mix-1024x585-1.png 1024w, assets/images/the-perfect-mix-1024x585-1-300x171.png 300w, assets/images/the-perfect-mix-1024x585-1-768x439.png 768w" sizes="(max-width: 1024px) 100vw, 1024px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="text-align: center;">
                                <h2>What we do</h2>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>App Strategy</b></h4>
                                    <p>Our multi-faceted team of project managers and app strategists work closely with clients to develop a powerful app development strategy for their unbaked app idea.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>App Designing</b></h4>
                                    <p>Our skilled app designers with lasting experience in app design field create exquisite apps that make solid impression on intended audience.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>App Development</b></h4>
                                    <p>Our highly-experienced app developers offer top-notch app development services by leveraging latest technologies and trend in app space.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>App Support & Maintenance</b></h4>
                                    <p>Our maintenance engineers provide comprehensive app support services and app maintenance services to keep client’s app up-to-date for end-users</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>App Prototyping</b></h4>
                                    <p>To reduce risk and cost involved in developing apps, we do wire-framing and prototyping, take early feedback and refine till the best.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Offshore Staffing</b></h4>
                                    <p>We provide quality hiring and staffing solutions with a full-fledged hardware and software setup and progressive working environment.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px; text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div>
                                <h2>Verticals We Serve</h2>
                            </div>

                            <div>
                                <img width="2537" height="830" src="assets/images/verticals.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/verticals.png 2537w, assets/images/verticals-300x98.png 300w, assets/images/verticals-1024x335.png 1024w, assets/images/verticals-768x251.png 768w, assets/images/verticals-1536x503.png 1536w, assets/images/verticals-2048x670.png 2048w" sizes="(max-width: 2537px) 100vw, 2537px" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="text-align: center;">
                                <h2>Our Process</h2>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Discovery</b></h4>
                                    <p>We work with your team to understand the scope of work, dependencies, goals and Key Performance Indicators(KPIs).</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Estimation</b></h4>
                                    <p>Based on documentation goals and requirements, we provide an estimate for the work required to implement your product.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Ongoing Support</b></h4>
                                    <p>If you need additional technical support such as troubleshooting, app monitoring and bug fixes or if you wish to develop additional features and functionalities – we are here to help.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>UI/UX Design</b></h4>
                                    <p>Once all requirements are documented, the team will create wire-frames, prototypes, and high-fidelity design to share with our clients.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Development</b></h4>
                                    <p>After proposed functionalities and design are approved by the client, our developers create high quality code, module by module.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Quality Analysis</b></h4>
                                    <p>We use rigorous ene-to-end testing to ensure everything has been implemented successfully, and perform regression testing, performance testing, and everything in between.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Production Release</b></h4>
                                    <p>We use application release automation and continuous integration tools to ensure your release management cycle goes as planned and that your application is launched bug-free.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Thorough Documentation</b></h4>
                                    <p>We produce in-depth release notes, flow diagrams and API documentation for each line of code written, ensuring that any developer can quickly get up to speed with our work.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Post Release Monitoring</b></h4>
                                    <p>Once your application is launched to production, we do sanity testing to make sure everything works as expected, monitoring the vital signs of your app as it is being used by real users.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px; text-align: center;">
                        <div class="backgroundColor" style="overflow: hidden">
                            <div>
                                <h2>You Dream and We Make It Happen</h2>
                            </div>
                            <div>
                                <p>We focus on your goals and objectives giving you dedicated team and working with you as ideas come to life during the development process. We specialise in building native/none-nativea apps across devices, platforms, and channels with a shared code base. Our App development practices will take complexity away.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ SOLUTION</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
        </div>
    </div>
</div>
