<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">About Us</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">About Us</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <p>Enovasions Pte Limited (formerly known as Information System Services established in 1997) is one of Fiji’s leading IT Consultancy provider basedin Suva, Fiji.</p>
                            <p>Enovasions are committed to increasing the competitive advantage of our clients by providing cutting edge solutions through innovation, research and the application of emerging technologies.</p>
                            <p>Providing you with high quality service and support is the fundamental principle of everything we do. All of our processes – from sales through to implementation and support – focus on providing business with the highest caliber service while meeting your budget and making sure you receive exceptional return from your investment.</p>
                            <p>We have extensive experience implementing business software solutions that help streamline business processes to achieve healthier bottom line for our clients.</p>
                            <p>Our team will ensure your business remains protected from any business disruptions. When issues arise, we own and fix them – then we take the time to analyse the situation to ensure that sufficient processes are implemented to eliminate the risk of recurring issues.</p>
                        </div>
                    </div>
                    
                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 50px;">
                        <div class="backgroundColorNone" style="padding-top: 0;">
                            <h2 style="font-size: 22px;">Mission, Vision and Values</h2>
                            <div class="container-fluid">
                                <div class="accordion" id="accordionExample">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <i style="margin-right: 22px;  color: #7A7A7A;" class="fa fa-plus"></i> Mission
                                            </button>
                                        </div>
                                        <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                “To combine business process and human intellect to deliver strategy solutions where our deliverables are measured in terms of business benefit by our customers.”
                                                We at Enovasions are committed to Quality, Excellence and Innovation in every endeavour. We believe in combining the best people, processes and technology to achieve excellent results consistently.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <i style="margin-right: 22px;  color: #7A7A7A;" class="fa fa-plus"></i> Vision
                                            </button>
                                        </div>
                                        <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div class="card-body">
                                                To be a trusted partner, committed to client’s success, helping organizations discover maximum value from their people and processes.
                                                <br><b>Our customers perceive us as a fair and reliable partner, which helps us establish sustainable customer relations. We lead by example when it comes to quality of service and the way we approach people. We ensure our growth through above-average profits in our business areas.</b>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <i style="margin-right: 22px; color: #7A7A7A;" class="fa fa-plus"></i> Values
                                            </button>
                                        </div>
                                        <div class="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p>Our shared values and guidelines provide a framework for the actions of our employees. Our values provide guidance and define what is most important for us in our day-to-day work. They connect us to each other – and to our customers and partners.</p>
                                                <p style="margin-bottom: 0;"><strong>Customer First</strong></p>
                                                <p>Our customers are the reason for our existence. We anticipate their needs and endeavor to meet and surpass their expectations.</p>
                                                <p style="margin-bottom: 0;"><strong>Passion for Excellence</strong></p>
                                                <p>We, strive to attain individual and team excellence through a spirit of continuous learning, lofty standards, new ideals and a commitment to high levels of achievement. Taking complete ownership and delivering on commitments. Our customers are the reason for our existence. We anticipate their needs and endeavor to meet and surpass their expectations.</p>
                                                <p style="margin-bottom: 0;"><strong>Honesty &amp; Integrity</strong></p>
                                                <p>We, nurture a culture of spontaneity and mutual trust. Impeccable ethics and morals will be the cornerstone of all our interactions and relationships.</p>
                                                <p style="margin-bottom: 0;"><strong>Innovation</strong></p>
                                                <p>We, continuously re-engineer our processes and mindsets and encourage individual creativity and improvisation in all our actions.</p>
                                                <p style="margin-bottom: 0;"><strong>Social Responsibilities</strong></p>
                                                <p>We, recognize, abide by and add value to the social environment, while embracing our responsibilities as a good corporate citizen in every country we operate.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header"  id="headingThree">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <i style="margin-right: 22px;  color: #7A7A7A;" class="fa fa-plus"></i> Leadership Guidelines
                                            </button>
                                        </div>
                                        <div class="collapse" id="collapseFour" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p>Our leadership guidelines define our shared understanding of leadership ability. They are authoritative, and compliance with them can be demanded by everyone in the company.</p>
                                                <p>As such, they form the basis of trust.</p>
                                                <p style="margin-bottom: 0;"><strong>Role models</strong></p>
                                                <p style="margin-bottom: 0;"><strong>We are role models for our employees.</strong></p>
                                                <p style="margin-bottom: 0;">we have a respectful, loyal relationship with our employees and resolve conflicts constructively while keeping our promises and being reliable and fair to our employees.</p>
                                                <p style="margin-bottom: 0;"><strong>Entrepreneurship &amp; Self Reliance</strong></p>
                                                <p style="margin-bottom: 0;">we acknowledge the abilities and interests of our employees and give them room to grow. We encourage learning from mistakes as a way of ensuring continual self-improvement.</p>
                                                <p>we give them responsibility and help them put it into practice</p>
                                                <p style="margin-bottom: 0;"><strong>Teamwork</strong></p>
                                                <p style="margin-bottom: 0;"><strong>We encourage teamwork among our employees.</strong></p>
                                                <p style="margin-bottom: 0;">we encourage a sense of togetherness and enjoyment of our work</p>
                                                <p style="margin-bottom: 0;">we encourage motivation and teamwork at the departmental level and throughout our company</p>
                                                <p>we create an atmosphere of mutual trust at work.</p>
                                                <p style="margin-bottom: 0;"><strong>Communication</strong></p>
                                                <p style="margin-bottom: 0;"><strong>We encourage open communication within the company.</strong></p>
                                                <p style="margin-bottom: 0;">we are unbiased in our discussions with employees, but value conflict resolution over harmony</p>
                                                <p>we encourage employees to communicate openly with all their fellow employees.</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div><!-- .accordion -->
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
