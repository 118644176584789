import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lshospitality',
  templateUrl: './lshospitality.component.html',
  styleUrls: ['./lshospitality.component.css']
})
export class LshospitalityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
