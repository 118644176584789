import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AppService } from "../app.service";
import { BlogsComponent } from '../about/blogs/blogs.component';

import * as myGlobals from "../globals";
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [BlogsComponent]
})

export class HomeComponent implements OnInit {

  paused: boolean = false;
  private readonly notifier: NotifierService;
  message_data:any = {};

  constructor(notifierService: NotifierService, private _appService: AppService, public _blog: BlogsComponent) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    $(window).scrollTop(0);
    $("#videoPlay").html("<video style='min-width: 100%; min-height: 100%; position: fixed; z-index: -100; left: 0; right: 0; bottom: 0; top: 0;' #movieplayer poster='assets/images/bwCity.png' id='bgvid' autoplay muted loop playsinline><source src='assets/images/bwCity.mov' type='video/mp4'></video>");
    setTimeout(() => this.scrollText(), 1000);
    this.message_data = {
        Name: "", Email: "", Subject: "", Message: ""
    };

    this._blog.loadLsRetailBlogsData();

    $(document).ready(function() {
      $('#testimonials').on('mouseenter onTouchStart onTouchMove', numberCounter);

      function numberCounter() {
        const maxCounter = $('.count-number').data('counter');
        $({
          Counter: 0
        }).animate({
          Counter: maxCounter
        }, {
          duration: 1000,
          easing: 'swing',
          step: function() {
            $('.count-number').text(Math.ceil(this.Counter)+"%");
          }
        });
      }
    });
  }  

  txtRotate(el: any, toRotate: any, period: any) {
    let loopNum = 0;
    let isDeleting = false;
    period = parseInt(period, 10) || 3000;
    let txt = "";
    this.tick(loopNum, toRotate, isDeleting, txt, el, period);
  }

  tick(loopNum: any, toRotate: any, isDeleting: any, txt: any, el: any, period: any) {
    let i = loopNum % toRotate.length;
    let fullTxt = toRotate[i];

    if (isDeleting) {
      txt = fullTxt.substring(0, txt.length - 1);
    } else {
      txt = fullTxt.substring(0, txt.length + 1);
    }

    el.innerHTML = "<span id='scollingText' style='font-size: 90px; color: #FF533D' class='wrap'>" + txt + "</span>";

    let that = this;
    let delta = 300 - Math.random() * 100;

    if (isDeleting) { delta /= 2; }

    if (!isDeleting && txt === fullTxt) {
      delta = period;
      isDeleting = true;
    } else if (isDeleting && txt === "") {
      isDeleting = false;
      loopNum++;
      delta = 500;
    }
    setTimeout(function () {
      that.tick(loopNum, toRotate, isDeleting, txt, el, period);
    }, delta);
  }

  scrollText() {
    // this.spinner.hide();
    let elements = document.getElementsByClassName("txt-rotate");
    for (let i = 0; i < elements.length; i++) {
      let toRotate = elements[i].getAttribute("data-rotate");
      let period = elements[i].getAttribute("data-period");
      if (toRotate) {
        this.txtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    let css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #FF533D }";
    document.body.appendChild(css);
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  messageForm(message_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    
    if (this.message_data.Name == "") {
      notifyArray[notifyArray.length] =  "Name is required.";
      this.notifier.notify('error', 'Name is required.');                                       
    }

    if (this.message_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.message_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.message_data.Subject == "" || this.message_data.Subject == undefined) {
      notifyArray[notifyArray.length] =  "Subject is required.";
      this.notifier.notify('error', 'Subject is required.');         
    } 

    if (this.message_data.Message == "" || this.message_data.Message == undefined) {
      notifyArray[notifyArray.length] =  "Message is required.";
      this.notifier.notify('error', 'Message is required.');         
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      /** spinner starts **/
      myGlobals.setLoadingData(true);
      
      let data = new FormData();
      data.append("message_data", JSON.stringify(this.message_data));
      this._appService.submitMessageForm(data).subscribe((item: any) => this.setMessageForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setMessageForm(item: any) {
    /** spinner ends **/
    myGlobals.setLoadingData(false);
    
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your message has been submitted successfully.";
      this.notifier.notify('success', item.message); 
      // return;
    } else {
        notifyArray[notifyArray.length] =  item.message;
        this.notifier.notify('error', item.message); 
        // return;
    }
    this.message_data = {};
  }

}
