import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { WorkwithusService } from "./workwithus.service";
import { AppService } from "../../app.service";

import * as myGlobals from "../../globals";
declare let $: any;

@Component({
  selector: 'app-workwithus',
  templateUrl: './workwithus.component.html',
  styleUrls: ['./workwithus.component.css']
})
export class WorkwithusComponent implements OnInit {

  private readonly notifier: NotifierService;
  form_data:any = {};
  radiooption:any = null;
  fileToUpload: any = null;
  fileToUploadName: string = "";
  knowMore: boolean = false;
  positionDetail: string = "";
  input:any = null;
  phoneInput:any = null;

  constructor(notifierService: NotifierService, private _appService: AppService) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.form_data = {
        FirstName: "", LastName: "", Email: "", Phone_Number: "", Linkedin_Profile: "", Location: "",
        Hear_About: "", Intro_Yourself: "", Applied_Position: ""
    };

    this.input = document.querySelector("#phone");
    this.phoneInput = (<any>window).intlTelInput(this.input, {
        preferredCountries: ["fj", "in"],
        hiddenInput : "full_phone",
        utilsScript: "../../assets/jquery/utils.min.js",
      });
  }

  gotoPositionForm() {
    $("#career-form")[0].scrollIntoView(true);
  }

  gotoCareerDetail(position: string) {
    this.knowMore = true;
    this.positionDetail = position;
    $(window).scrollTop(0);
  }

  setAppliedPosition(num: number, position: string) { 
    this.radiooption = num;
    this.form_data.Applied_Position = position; 
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  handleFileInput(files: any) {
    this.fileToUpload = files.target.files.item(0);
    this.fileToUploadName = this.fileToUpload.name;
  }

  applyPosition(form_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    let FILE_REGEXP = new RegExp("(.*?)\.(docx|doc|pdf)$");

    let input = this.input;
    let phoneInput = this.phoneInput

    let extension = phoneInput.getSelectedCountryData().dialCode;
    let phoneNumber = phoneInput.getNumber();
    this.form_data.Phone_Number = phoneNumber;
    
    if (this.radiooption == null || this.radiooption == undefined) {
      notifyArray[notifyArray.length] =  "Please select the position you are applying for.";
      this.notifier.notify('error', 'Please select the position you are applying for.');                                       
    } 

    if (this.form_data.FirstName == "") {
      notifyArray[notifyArray.length] =  "First name is required.";
      this.notifier.notify('error', 'First name is required.');                                       
    } 
    else if (myGlobals.isAllNumeric(this.form_data.FirstName) || !myGlobals.isAllCharacters(this.form_data.FirstName)) {
      notifyArray[notifyArray.length] =  "First name must contain characters.";
      this.notifier.notify('error', 'First name must contain characters.');
    } 
    
    if (this.form_data.LastName == "") {
      notifyArray[notifyArray.length] =  "Last name is required.";
      this.notifier.notify('error', 'Last name is required.');
    } 
    else if (myGlobals.isAllNumeric(this.form_data.LastName) || !myGlobals.isAllCharacters(this.form_data.LastName)) {
      notifyArray[notifyArray.length] =  "Last name must contain characters.";
      this.notifier.notify('error', 'Last name must contain characters.');
    } 
    
    if (this.form_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.form_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.form_data.Phone_Number == "" || this.form_data.Phone_Number == undefined) {
      notifyArray[notifyArray.length] =  "Phone number is required.";
      this.notifier.notify('error', 'Phone number is required.');         
    } 
    
    if (this.form_data.Location == "" || this.form_data.Location == undefined) {
      notifyArray[notifyArray.length] =  "Location is required.";
      this.notifier.notify('error', 'Location is required.');         
    }
    
    if (this.fileToUpload == undefined || this.fileToUpload == null) {
      notifyArray[notifyArray.length] =  "Please attach your Resume.";
      this.notifier.notify('error', 'Please attach your Resume.');         
    } else if (!FILE_REGEXP.test(this.fileToUploadName)) {
      notifyArray[notifyArray.length] =  "Please select correct file format.";
      this.notifier.notify('error', 'Please select correct file format.'); 
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      myGlobals.setLoadingData(true);
      let data = new FormData();
      data.append("form_data", JSON.stringify(this.form_data));
      data.append("file", this.fileToUpload, this.fileToUploadName);
      this._appService.submitForm(data).subscribe((item: any) => this.setSubmitForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setSubmitForm(item: any) {
    myGlobals.setLoadingData(false);
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your application has been submitted successfully.";
      this.notifier.notify('success', item.message);
    } else {
        notifyArray[notifyArray.length] =  item.message;
        this.notifier.notify('error', item.message);
    }
    this.form_data = {};
    this.radiooption = null;
    this.fileToUpload = null;
    this.fileToUploadName = "";
  }

}
