declare var $: any;
export var loading: boolean = false;

export function setLoadingData(flag: boolean) {
    loading = flag;
}

export function isNumberKey(evt: any) {
    let key = evt.keyCode || evt.charCode;
    if (evt.shiftKey == true)
        return false;
    if ((key >= 35 && key <= 40) || (key >= 48 && key <= 57) || key == 46 || key == 8 || key == 9 || key == 110 || key == 190 || (key >= 96 && key <= 105)) {
    } else
        return false;
    if (evt.target.value.indexOf(".") !== -1 && (key == 190 || key == 110))
        return false;
    return true;
}

export function isAllNumeric(val: any) {
    let num = /^[0-9]+$/;
    if (num.test(val))
        return true;
    return false;
}

export function isAllCharacters(val: any) {
    let char = /^[a-zA-Z]+$/;
    if (char.test(val))
        return true;
    return false;
}

export function blockSpecialChar(evt: any) {
    var regex = new RegExp("^[0-9a-zA-Z'. \b]+$");
    var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
    if (!regex.test(key)) {
        evt.preventDefault();
        return false;
    }
    return true;
}

export function isValidMailFormat(email: any) {
    let EMAIL_REGEXP =  /^([\w\-]+)(\.[\w]+)*@([\w\-]+\.){1,5}([A-Za-z]){2,4}$/;
    if (!EMAIL_REGEXP.test(email)) {
        return false;
    }
    return true;
}

export function ConvertToCSV(objArray: any) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    for (let index in objArray[0]) {
        // Now convert each value to string and comma-separated
        row += index + ",";
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += row + "\r\n";

    for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
            if (line != "") line += ",";

            line += array[i][index];
        }
        str += line + "\r\n";
    }
    return str;
}
