<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Blogs</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Blogs</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 50px;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px; text-align: center;">LS RETAIL BLOGS</h2>		
                            </div>
                            <div *ngFor="let retailBlogs of lsretailblogs; let cnt = index; let even = even; let odd = odd">
                                <article>
                                    <div class="boxcard" [ngClass]="[((cnt+1)%3==0 && cnt!=1) ? 'odd' : '', ((cnt+1)>3) ? 'margin-top20px' : '']">
                                        <!-- <div class="entry-thumbnail">
                                            <a href="{{retailBlogs.link}}" ><img width="720" height="480" src="{{retailBlogs.thumbnail}}" alt="" sizes="(max-width: 720px) 100vw, 720px" /></a>
                                        </div> -->
                                        <header class="entry-header">
                                            <h2 class="entry-title h5">
                                                <a href="{{retailBlogs.link}}" rel="bookmark" target="_blank">{{retailBlogs.title}}</a>
                                            </h2>            
                                        </header> 
                                        <section class="entry-content">
                                            <div id="wrap-text" innerHtml="{{retailBlogs.description}}"></div>
                                        </section>
                                        <footer class="entry-footer">
                                            <div class="meta bottom">
                                                <span class="date-post h6 span-footer-left">{{retailBlogs.author}}</span>
                                                <span class="date-post h6 span-footer-right">{{retailBlogs.pubDate}}</span>
                                                <div class="clearfix"></div>
                                            </div>
                                        </footer>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 50px;">
                        <div class="backgroundColorNone" style="overflow: hidden;">
                            <div>
                                <h2 style="font-size: 22px; text-align: center;">MICROSOFT BLOGS</h2>		
                            </div>
                            <div *ngFor="let msBlogs of msblogs; let cnt = index; let even = even; let odd = odd">
                                <article>
                                    <div class="boxcard1" [ngClass]="[((cnt+1)%3==0 && cnt!=1) ? 'odd' : '', ((cnt+1)>3) ? 'margin-top20px' : '']">
                                        <!-- <div class="entry-thumbnail">
                                            <a href="{{msBlogs.link}}" ><img width="720" height="480" src="{{msBlogs.thumbnail}}" alt="" sizes="(max-width: 720px) 100vw, 720px" /></a>
                                        </div> -->
                                        <header class="entry-header">
                                            <h2 class="entry-title h5">
                                                <a href="{{msBlogs.link}}" rel="bookmark" target="_blank">{{msBlogs.title}}</a>
                                            </h2>            
                                        </header> 
                                        <section class="entry-content">
                                            <div id="wrap-text1" innerHtml="{{msBlogs.description}}"></div>
                                        </section>
                                        <footer class="entry-footer">
                                            <div class="meta bottom">
                                                <div class="date-post h6 span-footer-left">{{msBlogs.author}}</div>
                                                <div class="date-post h6 span-footer-right">{{msBlogs.pubDate}}</div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </footer>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
        </div>
    </div>
</div>
