<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Dynamics 365 NAV</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Dynamics 365 NAV</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 0;">
                                <h2 style="font-size: 22px;">Microsoft Dynamics NAV: Business Management for Midsize, Growing Companies</h2>
                                <p>Microsoft Dynamics NAV is an ERP solution designed to help midsize and smaller, growing businesses manage and streamline their finance, operations, manufacturing, and supply chain. The solution’s business intelligence and reporting capabilities are invaluable in fast-moving companies, and its multi-currency and multilanguage features help many of Enovasions’ internationally operating customers run their businesses. For process manufacturers, Dynamics NAV provides a sound foundation for the more advanced functionality of Enovasions award-winning industry solutions.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 0;">
                                <iframe style="height: 300px;" allowfullscreen title="youtube Video Player" src="https://www.youtube.com/embed/8W_cwdIrWF4?autoplay=1&mute=1"></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="text-align: center; margin-bottom: 40px;">
                                <h2 style="font-size: 22px;">Microsoft Flow – Automate Process & Tasks</h2>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12" style="padding-right: 20px;">
                                <div>
                                    <h2 style="font-size: 20px;">Simplicity</h2>
                                </div>
                                <div>
                                    <p>Microsoft Dynamics NAV offers a familiar Microsoft user interface and seamlessly integrates into existing Microsoft applications such as Office, SharePoint, CRM, etc. This minimises training and means that the software is easily accepted by your employees. The consistent role concept helps each employee carry out their specific tasks efficiently.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12" style="padding-right: 20px;">
                                <div>
                                    <h2 style="font-size: 20px;">Cost-effectiveness</h2>
                                </div>
                                <div>
                                    <p>Microsoft Dynamics NAV is modular in design. You will receive modular functionality for company management, designed specifically for medium-sized businesses with industry-specific requirements. The modules of Microsoft Dynamics NAV that you choose are based on proven standards and are therefore quick to deploy and maintain.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h2 style="font-size: 20px;">Flexibility</h2>
                                </div>
                                <div>
                                    <p>Whether you want to expand functionality or users (or even reduce them), Microsoft Dynamics NAV can be adapted to your individual needs thanks to its open architecture. So, with the industry and special solutions Yrident have developed, can map the particular requirements of your industry as well as the specific features of your company.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="margin-bottom: 40px;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">Meets your Most Specific Needs</h2>
                                <p>The rich functionality provides endless possibilities for enhancement of performance.</p>
                            </div>
                            <div>
                                <div class="boxcard">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Financial Management</b></h4>
                                        <p>Efficiently manage accounting, inventory and cash flows, control fixed assets, process receivables, operate with multiple currencies, locations and companies.</p>
                                    </div>
                                </div>
                                <div class="boxcard">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Supply Chain Management</b></h4>
                                        <p>Set-up custom approvals, manage inventories, improve response time, seek new market opportunities, and collaborate more efficiently with your partners.</p>
                                    </div>
                                </div>
                                <div class="boxcard">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Project Management</b></h4>
                                        <p>Prepare budgets, plan capacities, automate invoicing, track utilisation and cost of resources.</p>  
                                    </div>
                                </div>
                                <div class="boxcard" style="margin-right: 0;">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>HR Management</b></h4>
                                        <p>Manage employee records, track absentees, prepare reports.</p>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Manufacturing</b></h4>
                                    <p>Improve planning of manufacture orders and deliveries, stay agile and ready to quickly respond to customer demands.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Sales and Marketing</b></h4>
                                    <p>Manage customers and sales data, create and tackle marketing campaigns, deliver exceptional services, manage contacts and agreements.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Service Management</b></h4>
                                    <p>Manage customer calls and inquiries, track support orders, allocate resources, create reports, manage assets.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Analysis</b></h4>
                                    <p>Manage budgets, consolidate reports, discover data relations, improve cooperation among employees.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_1" class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="margin-bottom: 40px;">
                                <h2 style="text-align: center; font-size: 22px;">Dynamics NAV Industry Solutions</h2>
                            </div>
                            <div>
                                <div class="boxcard1">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Retail</b></h4>
                                        <p>Based on Microsoft technology together with LS Retail, this solution allows retailers to focus on connecting with customers and delivering a complete personalised shopping experience across all channels.</p>
                                    </div>
                                </div>
                                <div class="boxcard1">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Manufacturing</b></h4>
                                        <p>The Dynamics NAV manufacturing functionality support discrete manufacturing as well as process manufacturing, and even has functionality supporting life sciences businesses.</p>
                                    </div>
                                </div>
                                <div class="boxcard1" style="margin-right: 0;">
                                    <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                    <div class="box-container">
                                        <h4><b>Warehouse & Distribution</b></h4>
                                        <p>Our solution for wholesale & distribution adds significant industry specific functionality to the award winning Dynamics NAV. The software delivers financial management along with integrated functionality.</p>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_2" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden; padding-top: 0;">
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Logistics & ICD</b></h4>
                                    <p>Our Logistics and ICD solution is an integrated end-to-end business management software solutions that blends the specific functionalty required by the Logistics industry.</p>
                                </div>
                            </div>
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>E-Commerce for Dynamics NAV</b></h4>
                                    <p>Microsoft Navision ERP system lets you bring together all your product information and business logic. It makes your web store an efficient and effective extension of your traditional sales activities.</p>
                                </div>
                            </div>
                            <div class="boxcard1" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Real Estate and Contracting</b></h4>
                                    <p>Enovasions’ vertical add-on Real Estate and Contracting that can be implemented on top of Microsoft Dynamics Navision. It is specifically designed to be full-range adaptive solution.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; DYNAMICS NAV</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
