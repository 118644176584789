import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-erp',
  templateUrl: './erp.component.html',
  styleUrls: ['./erp.component.css']
})
export class ErpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.getAccordion("#tabs",768);
  }

  getAccordion(element_id: any,screen: any) 
  {
    $(window).resize(function () { location.reload(); });

    if ($(window).width() < screen) 
    {
      let concat = '';
      let obj_tabs = $( element_id + " li" ).toArray();
      let obj_cont = $( ".tab-content .tab-pane" ).toArray();
      $.each( obj_tabs, function(n: any, val: any) 
      {
        concat += '<div id="' + n + '" class="panel panel-default">';
        concat += '<div class="panel-heading" role="tab" id="heading' + n + '">';
        concat += '<h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse' + n + '" aria-expanded="false" aria-controls="collapse' + n + '">' + val.innerText + '</a></h4>';
        concat += '</div>';
        concat += '<div id="collapse' + n + '" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading' + n + '">';
        concat += '<div class="panel-body">' + obj_cont[n].innerHTML + '</div>';
        concat += '</div>';
        concat += '</div>';
      });
      $("#accordion").html(concat);
      $("#accordion").find('.panel-collapse:first').addClass("in");
      $("#accordion").find('.panel-title a:first').attr("aria-expanded","true");
      $(element_id).remove();
      $(".tab-content").remove();
    }	
  }

}
