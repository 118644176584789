import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AppService } from "../../app.service";

import * as myGlobals from "../../globals";
declare let $: any;

@Component({
  selector: 'app-webdevelop',
  templateUrl: './webdevelop.component.html',
  styleUrls: ['./webdevelop.component.css']
})
export class WebdevelopComponent implements OnInit {

  private readonly notifier: NotifierService;
  query_data:any = {};
  input:any = null;
  phoneInput:any = null;

  constructor(notifierService: NotifierService, private _appService: AppService) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.query_data = {
        Name: "", Company: "", Phone: "", Email: "",  Required: "", Query: ""
    };

    this.input = document.querySelector("#webphone");
    this.phoneInput = (<any>window).intlTelInput(this.input, {
        preferredCountries: ["fj", "in"],
        hiddenInput : "full_phone",
        utilsScript: "../../assets/jquery/utils.min.js",
      });
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  queryEnquiryForm(query_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    let input = this.input;
    let phoneInput = this.phoneInput

    let extension = phoneInput.getSelectedCountryData().dialCode;
    let phoneNumber = phoneInput.getNumber();
    this.query_data.Phone = phoneNumber;
    
    if (this.query_data.Name == "") {
      notifyArray[notifyArray.length] =  "Name is required.";
      this.notifier.notify('error', 'Name is required.');                                       
    }

    if (this.query_data.Company == "") {
      notifyArray[notifyArray.length] =  "Company name is required.";
      this.notifier.notify('error', 'Company name is required.');
    }

    if (this.query_data.Phone == "" || this.query_data.Phone == undefined) {
      notifyArray[notifyArray.length] =  "Phone number is required.";
      this.notifier.notify('error', 'Phone number is required.');         
    }

    if (this.query_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.query_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.query_data.Required == "" || this.query_data.Required == undefined) {
      notifyArray[notifyArray.length] =  "App Required For is required.";
      this.notifier.notify('error', 'App Required For is required.');         
    } 

    if (this.query_data.Query == "" || this.query_data.Query == undefined) {
      notifyArray[notifyArray.length] =  "Your Query in Detail is required.";
      this.notifier.notify('error', 'Your Query in Detail is required.');         
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      myGlobals.setLoadingData(true);
      let data = new FormData();
      data.append("query_data", JSON.stringify(this.query_data));
      this._appService.submitEnquiryForm(data).subscribe((item: any) => this.setEnquiryForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setEnquiryForm(item: any) {
    myGlobals.setLoadingData(false);
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your Query has been submitted successfully.";
      this.notifier.notify('success', item.message); 
    } else {
        notifyArray[notifyArray.length] =  item.message;
        this.notifier.notify('error', item.message); 
    }
    this.query_data = {};
  }

}
