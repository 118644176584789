import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ContactusComponent } from './contactus/contactus.component';
import { RetailComponent } from './industries/retail/retail.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { EcommerceComponent } from './industries/ecommerce/ecommerce.component';
import { LogisticsComponent } from './industries/logistics/logistics.component';
import { ManufacturingComponent } from './industries/manufacturing/manufacturing.component';
import { BankingComponent } from './industries/banking/banking.component';
import { ErpComponent } from './services/erp/erp.component';
import { VmsComponent } from './services/vms/vms.component';
import { MsofficeComponent } from './services/msoffice/msoffice.component';
import { UpgradebussinesscentralComponent } from './services/upgradebussinesscentral/upgradebussinesscentral.component';
import { SystemintegrationComponent } from './services/systemintegration/systemintegration.component';
import { WebdevelopComponent } from './services/webdevelop/webdevelop.component';
import { SupportComponent } from './services/support/support.component';
import { ItstrategyComponent } from './services/itstrategy/itstrategy.component';
import { ItsecurityComponent } from './services/itsecurity/itsecurity.component';
import { BusinesscentralComponent } from './products/businesscentral/businesscentral.component';
import { DynamicsnavComponent } from './products/dynamicsnav/dynamicsnav.component';
import { LscentralComponent } from './products/lscentral/lscentral.component';
import { LshospitalityComponent } from './products/lshospitality/lshospitality.component';
import { MsazureComponent } from './products/msazure/msazure.component';
import { PowerbiComponent } from './products/powerbi/powerbi.component';
import { HrmsComponent } from './products/hrms/hrms.component';
import { Msoffice365Component } from './products/msoffice365/msoffice365.component';
import { MssharepointComponent } from './products/mssharepoint/mssharepoint.component';
import { VeritasComponent } from './products/veritas/veritas.component';
import { TrendmicroComponent } from './products/trendmicro/trendmicro.component';
import { CloudComponent } from './digital/cloud/cloud.component';
import { WebappdevelopComponent } from './digital/webappdevelop/webappdevelop.component';
import { MobileappdevelopComponent } from './digital/mobileappdevelop/mobileappdevelop.component';
import { ChatbotComponent } from './digital/chatbot/chatbot.component';
import { HrmsonlineComponent } from './digital/hrmsonline/hrmsonline.component';
import { VmsonlineComponent } from './digital/vmsonline/vmsonline.component';
import { AboutusComponent } from './about/aboutus/aboutus.component';
import { WorkwithusComponent } from './about/workwithus/workwithus.component';
import { BlogsComponent } from './about/blogs/blogs.component';
import { BlogdetailComponent } from './about/blogs/blogdetail/blogdetail.component';
import { CareerdetailComponent } from './about/workwithus/careerdetail/careerdetail.component';
import { SteelComponent } from './industries/manufacturing/steel/steel.component';
import { FoodComponent } from './industries/manufacturing/food/food.component';
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  { path: "app", component: AppComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "retail-industry", component: RetailComponent },
  { path: "hospitality-industry", component: HospitalityComponent },
  { path: "ecommerce-industry", component: EcommerceComponent },
  { path: "logistics-industry", component: LogisticsComponent },
  // { path: "manufacturing-industry", component: ManufacturingComponent },
  { path: "food-and-beverage-industry", component: FoodComponent },
  { path: "steel-and-metals-industry", component: SteelComponent },
  { path: "banking-industry", component: BankingComponent },
  { path: "erp-services", component: ErpComponent },
  { path: "vms-services", component: VmsComponent },
  { path: "ms-office-services", component: MsofficeComponent },
  { path: "business-central-services", component: UpgradebussinesscentralComponent },
  { path: "system-integration-services", component: SystemintegrationComponent },
  { path: "web-development-services", component: WebdevelopComponent },
  { path: "support-services", component: SupportComponent },
  { path: "it-strategy-services", component: ItstrategyComponent },
  { path: "it-security-services", component: ItsecurityComponent },
  { path: "business-central-solutions", component: BusinesscentralComponent },
  { path: "dynamics-nav-solutions", component: DynamicsnavComponent },
  { path: "ls-nav-solutions", component: LscentralComponent },
  { path: "ls-retail-hospitality-solutions", component: LshospitalityComponent },
  { path: "microsoft-azure-solutions", component: MsazureComponent },
  { path: "power-bi-solutions", component: PowerbiComponent },
  { path: "hrms-payroll-solutions", component: HrmsComponent },
  { path: "ms-office-365-solutions", component: Msoffice365Component },
  { path: "ms-sharepoint-solutions", component: MssharepointComponent },
  { path: "veritas-solutions", component: VeritasComponent },
  { path: "trend-micro-solutions", component: TrendmicroComponent },
  { path: "digital-cloud-solutions", component: CloudComponent },
  { path: "digital-web-development-solutions", component: WebappdevelopComponent },
  { path: "digital-mobile-development-solutions", component: MobileappdevelopComponent },
  { path: "digital-chatbot-solutions", component: ChatbotComponent },
  { path: "hrms-payroll-online-solutions", component: HrmsonlineComponent },
  { path: "vms-online-solutions", component: VmsonlineComponent },
  { path: "about-us", component: AboutusComponent },
  { path: "work-with-us", component: WorkwithusComponent },
  { path: "work-with-us/:id", component: CareerdetailComponent },
  { path: "blogs", component: BlogsComponent },
  { path: "blogs/:id", component: BlogdetailComponent },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404' }
  // { path: "**", component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
