import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgradebussinesscentral',
  templateUrl: './upgradebussinesscentral.component.html',
  styleUrls: ['./upgradebussinesscentral.component.css']
})
export class UpgradebussinesscentralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
