<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">Hospitality Industry</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">Hospitality Industry</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <p>Intense competition and an unrelenting focus on cost control and profitability are driving retail procure-to-pay teams to look at new ways of doing business, including new operating models, controls and technologies. The increasing trend for online and mobile ordering is changing the face of Retail Hospitality and Leisure. So too is customers’ use of social media to express their satisfaction – or otherwise!</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-building fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>PROPERTY MANAGEMENT</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>POINT OF SALE</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-credit-card fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>PAYMENT GATEWAY</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-area-chart fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>BUSINESS ANALYTICS</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-bar-chart fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>INVENTORY &amp; PROCUREMENT</b></h4> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-list-alt fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>RESERVATION &amp; TABLE MANAGEMENT</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-flag-o fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>ACTIVITY SCHEDULING</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-book fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>DOCUMENT MANAGEMENT</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-users fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>WORKFORCE MANAGEMENT</b></h4> 
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-cogs fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>SERVICES</b></h4> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 80px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div>
                                <h2 style="font-size: 22px; text-align: center;">Digital Transformation</h2>		
                            </div>
                            <div>
                                <p>Digital is no longer a purview of only Banking, Insurance, Healthcare or Retail. The Restaurant industry is having pressure from multiple directions. </p>
                                <p>Today’s consumer expects fresh food, whether it is in season or not, with an exotic dining experience.</p>
                                <p>Successful restaurants recognize that the easy path to their customers’ stomachs begins in their minds. They need to grab customer’s attention and entice them with a memorable experience in order to trigger repeat visits.</p>
                                <p>Here are some of the applications of Digital disruption in the restaurants &amp; food service industry:</p>
                                
                                <ul style="padding-left: 40px;">
                                    <li><b>Digital Signage</b> to deliver eye-catching graphics to engage customers the moment they walk through the door</li>
                                    <li>Online reservations using mobile app &amp; flexibility of customization of menu as per customer taste.</li>
                                    <li>Loyalty programs based on Big data and analytics, the businesses in the hospitality sector are not only able to create laser targeted marketing campaigns but are also able to measure success.</li>
                                    <li><b>Digital supply chains</b> to accurate demand forecasting, inventory optimization, and cost reduction.</li>
                                    <li><b>Chatbots:</b> Restaurants are using virtual assistants to respond to customer inquiries and to process and customize customer orders. Taco Bell, Pizza Hut have adopted chatbots to automate ordering process from a social media platform.</li>
                                    <li><b>Robots –</b> Restaurants are using AI-driven robots to increase capacity and speed of food preparation and delivery.</li>
                                    <li>Developers are designing applications which use AI to help consumers choose meals &amp; suggest foods based on their eating preferences.</li>
                                    <li><b>Kiosks –</b> Restaurants are integrating AI-driven self-service Kiosks to reduce customer waiting time and enhance the customer ordering experience.</li>
                                    <li>Pay by phone and <b>flexible paying options</b></li>
                                </ul>
                                
                                <br>
                                <p><b>DIGITAL’S IMPACT—TODAY AND TOMORROW</b></p>
                                <p>Four clear conclusions emerge from the recent evolution of digital in the restaurant industry:</p>
                                
                                <ul style="padding-left: 40px;">
                                    <li>Consumers have embraced digital, and it is changing the game more quickly than many appreciate.</li>
                                    <li>Early movers have a significant advantage.</li>
                                    <li>In order to compete, brands need a well-defined digital strategy backed by well-chosen investment priorities.</li>
                                    <li>There is no one-size-fits-all answer, although there are some common “table stakes” moves that all brands should consider.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ HOSPITALITY SOLUTION</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
</div>
