import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AppService } from "../app.service";
import { BlogsComponent } from '../about/blogs/blogs.component';

import * as myGlobals from "../globals";
declare let $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [BlogsComponent]
})
export class FooterComponent implements OnInit {

  private readonly notifier: NotifierService;
  enquiry_data:any = {};
  input:any = null;
  phoneInput:any = null;

  constructor(notifierService: NotifierService, private _appService: AppService, public _blog: BlogsComponent) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.enquiry_data = {
        Name: "", Email: "", Phone: "", Company: "", Address: "", Message: ""
    };

    this._blog.loadLsRetailBlogsData();
    
    this.input = document.querySelector("#phone");
    this.phoneInput = (<any>window).intlTelInput(this.input, {
        preferredCountries: ["fj", "in"],
        hiddenInput : "full_phone",
        utilsScript: "../../assets/jquery/utils.min.js",
      });
  }

  blockSpecialChar(evt: any) { 
    return myGlobals.blockSpecialChar(evt); 
  }

  isNumberKey(evt: any) { 
    return myGlobals.isNumberKey(evt); 
  }

  validateEmail(evt: any) {
      let email = evt.target.value;

      if (!myGlobals.isValidMailFormat(email)) {
        this.notifier.notify('warning', 'Please provide a valid email.');
      }
  }

  quickEnquiryForm(enquiry_data: any) {
    let notifyArray = [];
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    let input = this.input;
    let phoneInput = this.phoneInput

    let extension = phoneInput.getSelectedCountryData().dialCode;
    let phoneNumber = phoneInput.getNumber();
    this.enquiry_data.Phone = phoneNumber;
    
    if (this.enquiry_data.Name == "") {
      notifyArray[notifyArray.length] =  "Name is required.";
      this.notifier.notify('error', 'Name is required.');                                       
    } 

    if (this.enquiry_data.Email == "") {
      notifyArray[notifyArray.length] =  "Email is required.";
      this.notifier.notify('error', 'Email is required.');
    } 
    else if (!EMAIL_REGEXP.test(this.enquiry_data.Email)) {
      notifyArray[notifyArray.length] =  "Email is not valid.";
      this.notifier.notify('error', 'Email is not valid.');                    
    } 

    if (this.enquiry_data.Phone == "" || this.enquiry_data.Phone == undefined) {
      notifyArray[notifyArray.length] =  "Phone number is required.";
      this.notifier.notify('error', 'Phone number is required.');         
    }

    if (this.enquiry_data.Company == "" || this.enquiry_data.Company == undefined) {
      notifyArray[notifyArray.length] =  "Company name is required.";
      this.notifier.notify('error', 'Company name is required.');
    } 

    if (this.enquiry_data.Address == "" || this.enquiry_data.Address == undefined) {
      notifyArray[notifyArray.length] =  "Address is required.";
      this.notifier.notify('error', 'Address is required.');         
    } 

    if (this.enquiry_data.Message == "" || this.enquiry_data.Message == undefined) {
      notifyArray[notifyArray.length] =  "Message is required.";
      this.notifier.notify('error', 'Message is required.');         
    }

    if(notifyArray.length > 0) {
      return;
    }
    else {
      myGlobals.setLoadingData(true);
      let data = new FormData();
      data.append("enquiry_data", JSON.stringify(this.enquiry_data));
      this._appService.submitQuickEnquiryForm(data).subscribe((item: any) => this.setQuickEnquiryForm(item));
    }
  }

  get loadingData(){
    return myGlobals.loading;
  }

  setQuickEnquiryForm(item: any) {
    myGlobals.setLoadingData(false);
    let notifyArray = [];
    
    if (item.status == "success") {
      notifyArray[notifyArray.length] =  "Your enquiry has been submitted successfully.";
      this.notifier.notify('success', item.message); 
      // return;
    } else {
      notifyArray[notifyArray.length] =  item.message;
      this.notifier.notify('error', item.message); 
      // return;
    }
    this.enquiry_data = {};
  }

}
