import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-hrms',
  templateUrl: './hrms.component.html',
  styleUrls: ['./hrms.component.css']
})
export class HrmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.getAccordion("accordion", "tab1", "#tab1", 768);
  }

  getAccordion(accordion: any, tab_id:any, element_id: any,screen: any) 
  {
    $(window).resize(function () { location.reload(); });

    if ($(window).width() < screen) 
    {
      let concat = '';
      let obj_tabs = $( element_id + " li" ).toArray();
      let obj_cont = $( element_id+"-content" + " .tab-pane" ).toArray();
      $.each( obj_tabs, function(n: any, val: any) 
      {
        concat += '<div id="'+ tab_id + "_" + n + '" class="panel panel-default">';
        concat += '<div class="panel-heading" role="tab" id="'+ tab_id +'-heading-' + n + '">';
        concat += '<h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#'+ accordion +'" href="#'+ tab_id +'-collapse-' + n + '" aria-expanded="false" aria-controls="'+ tab_id +'-collapse-' + n + '">' + val.innerText + '</a></h4>';
        concat += '</div>';
        concat += '<div id="'+ tab_id +'-collapse-' + n + '" class="panel-collapse collapse" role="tabpanel" aria-labelledby="'+ tab_id +'-heading-' + n + '">';
        concat += '<div class="panel-body">' + obj_cont[n].innerHTML + '</div>';
        concat += '</div>';
        concat += '</div>';
      });
      $("#"+accordion+"").html(concat);
      $("#"+accordion+"").find('.panel-collapse:first').addClass("in");
      $("#"+accordion+"").find('.panel-title a:first').attr("aria-expanded","true");
      $(element_id).remove();
      $(element_id+"-content").remove();
    }	
  }

}
