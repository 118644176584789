import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  count:number = 0;
  count1:number = 0;

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function() {
      $(".menu-toggle>.menu-show").click(function() {
        $(".menu-toggle").addClass("toggled-on");
        $(".main-navigation").addClass("toggled-on");
        $(".menu-toggle").attr("aria-expanded","true");
      });

      $(".menu-toggle>.menu-close").click(function() {
        $(".menu-toggle").removeClass("toggled-on");
        $(".main-navigation").removeClass("toggled-on");
        $(".menu-toggle").attr("aria-expanded","false");
      });

      $(".sub-menu a").click(function() {
        $(".dropdown-toggle").removeClass("toggled-on");
        $(".sub-menu").removeClass("toggled-on");
        $(".dropdown-toggle").attr("aria-expanded","false");

        $(".menu-toggle").removeClass("toggled-on");
        $(".main-navigation").removeClass("toggled-on");
        $(".menu-toggle").attr("aria-expanded","false");
      });
    });
  }

  dropdownToggle(id: any) {
    if(this.count == 0) {
      $('.'+id+'>i').click(function() {
        $(".dropdown-toggle").removeClass("toggled-on");
        $(".sub-menu").removeClass("toggled-on");
        $(".dropdown-toggle").attr("aria-expanded","false");

        $('.'+id).addClass("toggled-on");
        $('#'+id).addClass("toggled-on");
        $('.'+id).attr("aria-expanded","true");
      });
      this.count++;
    } else {
      $(".dropdown-toggle").removeClass("toggled-on");
      $(".sub-menu").removeClass("toggled-on");
      $(".dropdown-toggle").attr("aria-expanded","false");
      this.count = 0;
    }
  }

  dropdownToggle1(id: any) {
    if(this.count1 == 0) {
      $('.'+id+'>i').click(function() {
        $('.'+id).addClass("toggled-on");
        $('#'+id).addClass("toggled-on");
        $('.'+id).attr("aria-expanded","true");
      });
      this.count1++;
    } else {
      $('.'+id).removeClass("toggled-on");
      $('#'+id).removeClass("toggled-on");
      $('.'+id).attr("aria-expanded","false");
      this.count1 = 0;
    }
  }

}
