<div id="main" class="site-main">
    <div class="header-image-wrapper">
        <div class="header-image with-header-image " style="background-image: url(assets/images/bg.png);">
            <div class="container" style="padding-left: 50px; padding-right: 50px;">
                <img class="videoPlayCss1" src="assets/images/bwCity.png" alt="background image">
                <h1 class="page-title">LS Central</h1>
            </div>
        </div>
    </div>

    <div class="pageBackgoundColor">
        <div class="container breadcrumb-wrapper">
            <div class="breadcrumb breadcrumbs sp-breadcrumbs">
                <div class="breadcrumb-trail" style="padding-left: 50px; padding-right: 50px;">
                    <a routerLink="/home" title="Enovasions" rel="home" class="trail-begin">Home</a> 
                    <span class="sep"><i class="fa fa-angle-right"></i></span> 
                    <span class="trail-end">LS Central</span>
                </div>
            </div>
        </div>
        
        <div class="container main-container">
            <div class="entry-content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <h2 style="font-size: 22px;">LS Central</h2>
                                <p>LS Central is an end-to-end retail POS solution for stores or chain of retails, which is powered by Microsoft Dynamics NAV. This integrated solution delivers completed and innovative functionalities to the busiest retailer without the need to build, manage and maintain multiple applications and interfaces. Its unique use of single application covers your whole retail business from the Point Of Sale (POS) terminals to headquarter. The powerful functions including store management, inventory, merchandising, demand planning and all the back office functions that you would expect to find at head office are available in LS Central.</p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <!-- <iframe class="center-block" style="height: 300px;" allowfullscreen title="youtube Video Player" src="https://www.youtube.com/embed/NQ-hF1AOXL4?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe> -->
                                <iframe style="min-height: 350px;"  width="853" height="480" src="https://www.youtube.com/embed/TDOD9W3xgr8?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="text-align: center; margin-bottom: 40px;">
                                <h2 style="font-size: 22px;">Why LS Central Software Solutions?</h2>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-right: 20px;">
                                <img width="658" height="443" src="assets/images/lsretail.png" class="attachment-full size-full center-block" alt="" loading="lazy" srcset="assets/images/lsretail.png 658w, assets/images/lsretail-300x202.png 300w" sizes="(max-width: 658px) 100vw, 658px" />
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left: 20px;">
                                <div>
                                    <h2 style="font-size: 22px; margin-bottom: 10px;">Fully Integrated All-in-one Software Solutions</h2>
                                    <p>Manage your business centrally from end to end with our flexible, custom-designed and cloud-enabled software solutions. Cover your whole operations from headquarters, check your performance in real time and react quickly to change.</p>
                                </div>
                                <div>
                                    <h2 style="font-size: 22px; margin-bottom: 10px;">Enhance Your Omni-channel Strategy</h2>
                                    <p>Fast-changing consumer habits demand innovative business systems. With LS Central’s cutting-edge omni-channel software solutions you can offer a seamless, personalized consumer experience across all channels and touch-points, including social media.</p>
                                </div>
                                <div>
                                    <h2 style="font-size: 22px; margin-bottom: 10px;">Offer Superior Customer Experience</h2>
                                    <p>Give your customers an outstanding shopping experience across all your sales channels and their loyalty and repeat business. Grow your clientele and margins by offering a fast and accurate service in-store, online and on-the-go, including personalized deals and more.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div style="margin-bottom: 40px;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">Meets Your Most Specific Needs</h2>
                                <p>The rich functionality provides endless possibilities for enhancement of performance.</p>
                            </div>
                            <div style="font-size: 15px;">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Head Office</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Product Lifecycle Management</li>
                                        <li>Manual and Automated Item Maintenance</li>
                                        <li>Dynamic Hierarchy, Attributes and Other Grouping of Product</li>
                                        <li>Price, Offer, Coupon and Campaign Managment</li>
                                        <li>Automobile Replenishment</li>
                                        <li>Replenishment by Allocation</li>
                                        <li>Serial / Lot Number Support</li>
                                        <li>Member Management – Loyalty Program</li>
                                        <li>Vendor Performance</li>
                                        <li>Sales Reporting and Analyzing</li>
                                        <li>Sales Commision</li>
                                        <li>Loss Prevention </li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Back Office</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Dashboard for Store Operations</li>
                                        <li>POS Management</li>
                                        <li>Cash Management</li>
                                        <li>Stock counting and Adjustments by Simple Worksheets</li>
                                        <li>Store Replenishment by Stock Request, Purchase and Transfer Orders</li>
                                        <li>Sales History</li>
                                        <li>Simple End of Day Statements</li>
                                        <li>Receiving and Picking Goods</li>
                                        <li>Flexible Architecture</li>
                                        <li>Serial / Lot Number Support</li>
                                        <li>Gift Registration </li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Mobile Device</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Mobile POS</li>
                                        <li>Receiving / Picking</li>
                                        <li>Stock Counting</li>
                                        <li>Stock Changes</li>
                                        <li>Label Ordering</li>
                                        <li>Stock Counting and Adjustment</li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Store Front Office (POS)</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Fast Checkout</li>
                                        <li>Simple Operation</li>
                                        <li>Multiple Interface</li>
                                        <li>Broad POS Hardware Support</li>
                                        <li>Item Cross Selling</li>
                                        <li>item Finder</li>
                                        <li>Member Management – Loyalty Program</li>
                                        <li>Serial / Lot Number Support</li>
                                        <li>Multiple Payments</li>
                                        <li>Special Order Entry at POS</li>
                                        <li>Item Configurator</li>
                                        <li>Operate locally – Configure Centrally</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 30px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="margin-bottom: 40px; text-align: center;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">LS Central – Vertical Solutions</h2>
                            </div>
                            <div style="font-size: 15px;">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Apparel &amp; Fashion</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Allocation Plans</li>
                                        <li>Replenishment Tools</li>
                                        <li>Lifecycle Management – Seasonality</li>
                                        <li>Speedy Creation of Products</li>
                                        <li>Member Management – Loyalty Program</li>
                                        <li>Targeted Technical Promotions</li>
                                        <li>Grading of Stores</li>
                                        <li>Distribution Engine</li>
                                        <li>Store Capacity, Stock Coverage</li>
                                        <li>POS Dynamics Interface</li>
                                        <li>Simple Processes at the POS</li>
                                        <li>Sales Commision</li>
                                        <li>Allocation Plan </li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Furniture</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Item Configurator</li>
                                        <li>Special Orders</li>
                                        <li>Make to Order/Tailor Made</li>
                                        <li>Pre-Payments</li>
                                        <li>Catalogue Sales</li>
                                        <li>Campaign and Offer Management</li>
                                        <li>Non Stock Items</li>
                                        <li>Replenishment-Automatic and Allocation</li>
                                        <li>Drop Shipment from Vendor</li>
                                        <li>Pickup and Delivery to Customer</li>
                                        <li>POS Dynamic Interface</li>
                                        <li>Simple Processes at the POS</li>
                                        <li>Sales Commission</li>
                                        <li>Allocation Plan </li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Electronic / Technical</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Dynamic Merchandise / Item hierarchy Level</li>
                                        <li>Open-to-Buy</li>
                                        <li>Special Orders</li>
                                        <li>Serial No. Tracking</li>
                                        <li>Campaign and Offer Management</li>
                                        <li>Member Management – Loyalty Schemes</li>
                                        <li>Advanced POS Functionality</li>
                                        <li>Item Cross Selling</li>
                                        <li>Item Configuration</li>
                                        <li>Item Finder</li>
                                        <li>POS Dynamic Interface</li>
                                        <li>Simple Processes at the POS</li>
                                        <li>Sales Commission</li>
                                        <li>Allocation Plan </li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <p><strong>Food &amp; Grocery</strong></p>
                                    <ul style="padding-left: 20px;">
                                        <li>Fast Checkout</li>
                                        <li>Customer Loyalty Program Accessible from POS</li>
                                        <li>Sales History &amp; Drill-down</li>
                                        <li>Vendor Performance</li>
                                        <li>Campaign Management</li>
                                        <li>Promotion, Discounts</li>
                                        <li>Mix and Match</li>
                                        <li>Offers, Coupons</li>
                                        <li>Simple Receiving Process (Mobile devices)</li>
                                        <li>Shrinkage Management</li>
                                        <li>POS Dynamic interface</li>
                                        <li>Simple Processes at the POS</li>
                                        <li>Sales Commission</li>
                                        <li>Allocation Plan </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColorNone">
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>See it in Action</b></h4>
                                    <p>See LS Central in action and learn just how easily you can integrate systems in your company.</p>
                                </div>
                            </div>
                            <div class="boxcard1">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Try it for Free</b></h4>
                                    <p>Try the LS Central platform for free and see how quickly you can save time and money. Start integration today.</p>
                                </div>
                            </div>
                            <div class="boxcard1" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Get a Quote</b></h4>
                                    <p>Ready to start using LS Central? Customers achieve rapid, measurable return on investment with LS Central.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_1" class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 50px;">
                        <div class="backgroundColor" style="overflow: hidden;">
                            <div style="margin-bottom: 40px; text-align: center;">
                                <h2 style="font-size: 22px; margin-bottom: 10px;">LS Central – Add-ons</h2>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Retail Omni -Channel</b></h4>
                                    <p>Become an omni-channel business with LS Omni. Offer multi-channel deals and promotions, create a unified brand experience across the touchpoints, and reward your customers for shopping from you online, in-store and on their mobile devices. Improve your speed and level of service in store with the LS Omni mobile POS.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Retail ForeCourt</b></h4>
                                    <p>Run a successful forecourt business — from the pumps, to the gas station, to the back office — with just one powerful, flexible solution. The system instantly replicates all information across the organization, so that you can always take informed business decisions based on up-to-date sales figures.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Warehouse & Distribution</b></h4>
                                    <p>Customers today are generally less inclined to wait at checkout counters, resulting in loss of sales. However, the traditional way installing more point of sale (POS) terminals at checkout counters is highly expensive in terms of both hardware as well as space requirements, especially given that customer footfalls in the store are highly variable.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="boxcard_2" class="col-lg-12 col-md-12 col-sm-12">
                        <div class="backgroundColor" style="overflow: hidden; padding-top: 0;">
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-road fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Logistics & ICD</b></h4>
                                    <p>Our Logistics and Inland Container Depot (ICD) solution is an integrated end-to-end business management software solutions that blends the specific functionalty required by the Logistics industry.</p>
                                </div>
                            </div>
                            <div class="boxcard">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-database fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>E-Commerce for Dynamics NAV</b></h4>
                                    <p>Microsoft Navision ERP system lets you bring together all your product information and business logic. It makes your web store an efficient and effective extension of your traditional sales activities.</p>
                                </div>
                            </div>
                            <div class="boxcard" style="margin-right: 0;">
                                <span style="font-size: 30px; color: #AB987A;"><i class="fa fa-desktop fa-2x fa-border" aria-hidden="true"></i></span>
                                <div class="box-container">
                                    <h4><b>Real Estate and Contracting</b></h4>
                                    <p>Enovasions’  vertical add-on Real Estate and Contracting that can be implemented on top of Microsoft Dynamics Navision. It is specifically designed to be full-range adaptive solution.</p>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                        <div class="backgroundColorNone">
                            <div>
                                <h2 style="font-size: 22px;">READY TO TAKE THE NEXT STEPS WITH ENOVASIONS’ &amp; LS CENTRAL</h2>		
                            </div>
                            <div>
                                <p>Contact us today to schedule a quick chat about your project!</p>
                            </div>
                            <div>
                                <div class="section-buttons animated anHidden">
                                    <!-- <a href="#" title="read more" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a> -->
                                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>
