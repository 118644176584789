<div id="main" class="site-main">
    <section id="big-section" class="big-section big-section-default">
        <div class="big-section-bg">
            <div>
                <div id="videoPlay"></div>
                <div id="intro" style="position: relative; height: 100vh; top: 200px;">
                    <h1 class="homeHeading">Business Solutions Made
                        <!-- <img src="assets/images/arrow.png"> -->
                        <br />
                        <br />
                        <span class="txt-rotate" data-period="2000" data-rotate='[ "SMART.", "EASY.", "COST EFFECTIVE.", "TRANSPARENT." ]'></span>
                    </h1>
                    <p>
                        <span style="font-weight: bolder; color: #FF533D; font-size: 20px">Enovasions</span> is helping to create flexible, cutting edge solutions for your organisation using tailored solutions for your specific business needs.
                    </p>
                    <!-- <p>
                        <button class="btn btn-primary" type="submit">Get Started</button>
                    </p> -->
                </div>
            </div>
        </div>
    </section>

    <section id="welcome" class="welcome-section white-section">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="section-content animated anHidden" data-animation="fadeInLeft">
                        <h2 class="section-title">Are you ready to Upgrade from Dynamics NAV to Dynamics 365 Business Central?</h2>
                        <div class="section-description">
                            The launch of Bussiness Central has posed this question to all the users of Navision as ERP what is the future?  Should they upgrade or stay on the current version?
                            <br/><br/>
                            Upgrading brings up thoughts of cost, pain, business disruption, and maybe even limited reward for all the effort. But it’s still something that one will have to evaluate as currently  NAV solution might be still meeting the needs now, however eventually support for the version will end, there will be more challenges around integrating with other applications and systems as they advance, and one can fall behind the competition because they’re taking advantage of the latest technology has to offer.
                        </div>
                        <div class="section-buttons">
                            <a routerLink="/business-central-services" title="read more" class="button">read more</a>
                        </div>
                    </div>
                </div>
                <div class="section-right animated anHidden" data-animation="fadeInRight">
                    <iframe style="margin-top: 150px;" width="680" height="400" src="https://www.youtube.com/embed/wVFZVajK2YI?autoplay=1&mute=1"></iframe>
                </div>
            </div>
        </div>
    </section>
		
    <section id="services" class="third-section grey-section">
        <div style="padding-top: 30px; padding-bottom: 30px;" class="container">
            <div class="section-header section-title">
                <h2 style="text-align: center;">SOLUTIONS - BUILT FOR YOUR INDUSTRY</h2>
            </div>

            <div class="row">
                <div class="col-lg-6 animated anHidden" data-animation="fadeInLeft">
                    <div class="box wow fadeInLeft">
                        <div class="icon"><img src="assets/images/retailImage.png" alt="retail logo"></div>
                        <h4 class="title"><a routerLink="/retail-industry">Retail</a></h4>
                        <p class="description">Retail ERP software solution which reduce complexity and improve the operating efficiency of your entire retail organization with one central solution <b><a routerLink="/retail-industry">Read More...</a></b></p>
                    </div>
                </div>

                <div class="col-lg-6 animated anHidden" data-animation="fadeInRight">
                    <div class="box wow fadeInRight">
                        <div class="icon"><img src="assets/images/hospitality.png" alt="hospitality logo"></div>
                        <h4 class="title"><a routerLink="/hospitality-industry">Hospitality</a></h4>
                        <p class="description">ERP for Hospitality, an organized kitchen, Flawless Service, Happy Customers, Cost Control, Staff management, Loss prevention, Loyalty and much more <b><a routerLink="/hospitality-industry">Read More...</a></b></p>
                    </div>
                </div>

                <div class="col-lg-6 animated anHidden" data-animation="fadeInLeft">
                    <div class="box wow fadeInLeft" data-wow-delay="0.2s">
                        <div class="icon"><img src="assets/images/e-commerce.png" alt="e-commerce logo"></div>
                        <h4 class="title"><a routerLink="/ecommerce-industry">E-Commerce</a></h4>
                        <p class="description">An e-commerce platform is a software application that lets you set up an online store and manage its marketing, sales, and operations <b><a routerLink="/ecommerce-industry">Read More...</a></b></p>
                    </div>
                </div>

                <div class="col-lg-6 animated anHidden" data-animation="fadeInRight">
                    <div class="box wow fadeInRight" data-wow-delay="0.2s">
                        <div class="icon"><img src="assets/images/logistics.png" alt="logistics logo"></div>
                        <h4 class="title"><a routerLink="/logistics-industry">Logistics</a></h4>
                        <p class="description">ERP for Transport & Logistics, helps organizations to manage Yard & Terminal Operations, Rail operations, Warehouse, Fleet & Delivery <b><a routerLink="/logistics-industry">Read More...</a></b></p>
                    </div>
                </div>

                <div class="col-lg-6 animated anHidden" data-animation="fadeInLeft">
                    <div class="box wow fadeInLeft" data-wow-delay="0.2s">
                        <div class="icon"><img src="assets/images/manufacturing.png" alt="manufacturing logo"></div>
                        <h4 class="title"><a routerLink="/manufacturing-industry">Manufacturing</a></h4>
                        <p class="description">Bring agility and efficiency to your manufacturing process by using an integrated solution that optimizes Production Planning, Operations & Scheduling <b><a routerLink="/manufacturing-industry">Read More...</a></b></p>
                    </div>
                </div>

                <div class="col-lg-6 animated anHidden" data-animation="fadeInRight">
                    <div class="box wow fadeInRight" data-wow-delay="0.2s">
                        <div class="icon"><img height="110" width="110" src="assets/images/corporate.png" alt="corporate logo"></div>
                        <h4 class="title"><a routerLink="/about-us">Enovasions Pte Limited</a></h4>
                        <p class="description">Enovasions is committed to increasing the competitive advantage of our clients by providing cutting edge solutions through innovation, research <b><a routerLink="/about-us">Read More...</a></b></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="features-blocks-section" class="features-blocks-section">
        <div class='clearfix'></div>
    </section>
		
    <section id="install" class="install-section transparent-section">
        <div class="container">
            <div class="section-content animated anHidden" data-animation="fadeIn">
                <h3 class="section-title">SERVICES Microsoft Dynamics 365 Partner</h3>
                <div class="section-description">
                    We provide strategic business and technology consulting services, including implementation, integration, migration, training, support, project management, as well as custom development. As your business grows and evolves, we are there every step of the way as your trusted advisor. <span style="color: #FF533D"><b>Enovasions Pte Limited</b></span> is one of the leading global providers of Information Technology services and business solutions with a proven track record of over 24+ years. We have been consistently adding value to the business bottom line of our global clientele. We not only help our clients to substantially bring down their costs, but also improve the business process. 
                </div>
                <div class="section-buttons">
                    <!-- <a href="#" title="Read more" class="button white-button">Read more</a> -->
                </div>
            </div>
        </div>
    </section>

    <section id="features" class="features-section white-section default-section">
        <div class="container">
            <div class="section-content">
                <h2 class="section-title">SOLUTIONS - BUILT FOR YOUR ORGANISATION</h2>
                <!-- <div class="section-description">What makes emmet theme unique</div> -->
                <div class="animated anHidden" data-animation="fadeInUp">
                    <div class="row">
                        <div class="widget theme_widget_features col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/dynamics365_icon.png" alt="Simplicity">
                                </div>
                                <h4 class="features-title">Business Central / NAV</h4>
                                <p class="features-content">Increase the efficiency and margins of your food service establishment, streamline processes, speed up tasks and make your customers happy...</p>
                            </div>
                        </div>

                        <div class="widget theme_widget_features  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/ls_retail_icon.png" alt="user friendly">
                                </div>
                                <h4 class="features-title">LS Retail / LS Central</h4>
                                <p class="features-content">Fast to implement and easy to learn, LS Retail includes a complete POS and back office software that enhance your customer service...</p>
                            </div>
                        </div>		

                        <div class="clearfix visible-sm-block"></div> 

                        <div class="widget theme_widget_features  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/power_bi_icon.png" alt="extremely fast">
                                </div>
                                <h4 class="features-title">Power BI</h4>
                                <p class="features-content">The intelligent cloud, powered by Microsoft, is just another part of the secret sauce behind your industry-specific deployment enabled by Enovasions...</p>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_features col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/azure_icon.png" alt="flexible configuration">
                                </div>
                                <h4 class="features-title">Microsoft Azure</h4>
                                <p class="features-content">Microsoft Azure is a growing collection of integrated cloud services—analytics, computing, database, mobile, networking, storage, and web—for moving faster...</p>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix visible-sm-block"></div> 

                    <div class="row">
                        <div class="widget theme_widget_features  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/payroll_hr_icon.png" alt="Simplicity">
                                </div>
                                <h4 class="features-title">HRMS &amp; Payroll</h4>
                                <p class="features-content">Web-based human resource management solution that provides attendance tracking, compliance, payroll management and more.</p>
                            </div>
                        </div>

                        <div class="widget theme_widget_features  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/ms_icon.png" alt="user friendly">
                                </div>
                                <h4 class="features-title">Microsoft Office 365</h4>
                                <p class="features-content">Microsoft 365 is designed to help you achieve more with innovative Office apps, intelligent cloud services, and world-class security.</p>
                            </div>
                        </div>		

                        <div class="clearfix visible-sm-block"></div> 

                        <div class="widget theme_widget_features  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/web_apps_icon.png" alt="extremely fast">
                                </div>
                                <h4 class="features-title">Mobile / Web Apps</h4>
                                <p class="features-content">Enovasions is a web and mobile app development company building reliable solutions for businesses and startups.</p>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_features col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="features-box">
                                <div class="features-icon rotateY">
                                    <img class="simplicity" src="assets/images/chatbot_icon1.png" alt="flexible configuration">
                                </div>
                                <h4 class="features-title">ChatBot / Livechat</h4>
                                <p class="features-content">Automate your customer service with ChatBot and never miss a chance to sell or help your customers.</p>
                            </div>
                        </div>

                        <div class="clearfix visible-sm-block"></div> 
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="portfolio" class="portfolio-section white-section default-section">
        <div class="container">
            <div class="section-content">
                <h2 class="section-title">Enovasions Service Offerings</h2>
                <!-- <div class="section-description">In the portfolio section you can display your works consisting of screenshots and additional information</div> -->
                
                <div class="portfolio-list">
                    <div class="portfolio-box  animated anHidden" data-animation="fadeInLeft">
                        <a class="portfolio-content">
                            <img src="assets/images/erpimage.png" class="attachment-thumb-portfolio wp-post-image" alt="ERP Implementation Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">ERP Implementation</h5>
                                        <div class="portfolio-categories">
                                            <span>Over 24+ years of experience with Microsoft Dynamics implementations & support.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInLeft">
                        <a class="portfolio-content">
                            <img src="assets/images/vatimage1.png" class="attachment-thumb-portfolio wp-post-image" alt="VAT Monitoring Compliance Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">VAT Monitoring Compliance (VMS)</h5>
                                        <div class="portfolio-categories">
                                            <span>Issue customers with VMS compliant invoices, credit notes, quotations.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInLeft">
                        <a class="portfolio-content">
                            <img src="assets/images/navd365.png" class="attachment-thumb-portfolio wp-post-image" alt="NAv To Business Central Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">Upgrade NAV To Business Central</h5>
                                        <div class="portfolio-categories">
                                            <span>As partner, we support our clients for seamless transmission during the platform upgrade or migration.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInLeft">
                        <a class="portfolio-content">
                            <img src="assets/images/systemimage1.png" class="attachment-thumb-portfolio wp-post-image" alt="System Integration Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">System Integration</h5>
                                        <div class="portfolio-categories">
                                            <span>Innovate and transform every part of your business with intelligent applications.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
    
                    <div class="portfolio-box  animated anHidden" data-animation="fadeInRight">
                        <a class="portfolio-content">
                            <img src="assets/images/webimage.png" class="attachment-thumb-portfolio wp-post-image" alt="Web Developement Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">App &amp; Web Apps Developement</h5>
                                        <div class="portfolio-categories">
                                            <span>Custom applications based on your requirements with the latest technology.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInRight">
                        <a class="portfolio-content">
                            <img src="assets/images/migrationimage1.png" class="attachment-thumb-portfolio wp-post-image" alt="Ofiice 365 Migration Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">Office 365 Migration</h5>
                                        <div class="portfolio-categories">
                                            <span>As a Migration Gold Partner, we support our clients for seamless transmission during the platform upgrade or migration.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInRight">
                        <a class="portfolio-content">
                            <img src="assets/images/itstrategyimage.png" class="attachment-thumb-portfolio wp-post-image" alt="IT Strategy Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">IT Strategy</h5>
                                        <div class="portfolio-categories">
                                            <span>We specialize in IT strategy consulting that helps you identify gaps and lay out a holistic IT strategy that allows you to manage your entire IT operation.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="portfolio-box  animated anHidden" data-animation="fadeInRight">
                        <a class="portfolio-content">
                            <img src="assets/images/itsecurityimage.png" class="attachment-thumb-portfolio wp-post-image" alt="IT Security Logo">
                            <div class="portfolio-hover">
                                <div class="hover-content">
                                    <div>
                                        <h5 class="portfolio-title">IT Security</h5>
                                        <div class="portfolio-categories">
                                            <span>Helping the enterprises in securing their infrastructure while transforming digitally.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="clearfix"></div>
                </div>
                
                <!-- <div class="section-buttons">
                    <a href="#portfolio" title="check all works" class="button white-button">check all works</a>
                </div> -->
            </div>
        </div>
    </section>

    <!-- <section id="plan" class="plan-section grey-section default-section">
        <div class="container">
            <div class="section-content">
                <h2 class="section-title">packages and pricing table</h2>
                <div class="section-description ">Pricing section for your product</div>
            
                <div class="row">
                    <div class="animated anHidden"  data-animation="fadeInRight">
                        <div class="widget theme_widget_plan  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="plan-box">
                                <div class="plan-header" style="background: #e7ac44">
                                    <h4 class="plan-name">Basic</h4>
                                </div>
                                <div class="plan-pricing">
                                    <span class="plan-currency">$</span>
                                    <span class="plan-amount">15</span>
                                    <span class="plan-period">/mo</span>
                                </div>   
                                <div class="plan-options">
                                    <p>100GB Storage</p>
                                    <p>All Themes</p>
                                    <p>Access to Tutorials</p>
                                    <p>Auto Backup</p>
                                    <p>Extended Security</p>
                                    <p>24/7 Support</p>
                                </div>
                                <div class="plan-button">
                                    <a href="#" class="button" style="background: #e7ac44" onMouseOver="this.style.backgroundColor = '#e8b661'" onMouseOut="this.style.backgroundColor = '#e7ac44'">sign up</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_plan  col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="plan-box ">
                                <div class="plan-header" style="background: #3ab0e2">
                                    <h4 class="plan-name">standard</h4>
                                </div>
                                <div class="plan-pricing">
                                    <span class="plan-currency">$</span>
                                    <span class="plan-amount">20</span>
                                    <span class="plan-period">/mo</span>
                                </div>   
                                <div class="plan-options">
                                    <p>100GB Storage
                                    </p><p>All Themes
                                    </p><p>Access to Tutorials
                                    </p><p>Auto Backup
                                    </p><p>Extended Security
                                    </p><p>24/7 Support</p>
                                </div>
                                <div class="plan-button">
                                    <a href="#" class="button" style="background: #3ab0e2" onMouseOver="this.style.backgroundColor = '#66bde2'" onMouseOut="this.style.backgroundColor = '#3ab0e2'"> sign up</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_plan  col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="plan-box recommend ">
                                <div class="plan-header" style="background: #27b399">
                                    <h4 class="plan-name">Premium</h4>
                                    <div class="plan-tagline">Best Value</div>
                                </div>
                                <div class="plan-pricing">
                                    <span class="plan-currency">$</span>
                                    <span class="plan-amount">35</span>
                                    <span class="plan-period">/mo</span>
                                </div>   
                                <div class="plan-options">
                                    <p>100GB Storage
                                    </p><p>All Themes
                                    </p><p>Access to Tutorials
                                    </p><p>Auto Backup
                                    </p><p>Extended Security
                                    </p><p>24/7 Support</p>
                                </div>
                                <div class="plan-button">
                                    <a href="#" class="button" style="background: #27b399" onMouseOver="this.style.backgroundColor = '#37c4aa'" onMouseOut="this.style.backgroundColor = '#27b399'"> sign up</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_plan  col-xs-12 col-sm-6 col-md-3 col-lg-3">        
                            <div class="plan-box ">
                                <div class="plan-header" style="background: #e96656">
                                    <h4 class="plan-name">ultimate</h4>
                                </div>
                                <div class="plan-pricing">
                                    <span class="plan-currency">$</span>
                                    <span class="plan-amount">99</span>
                                    <span class="plan-period">/mo</span>
                                </div>   
                                <div class="plan-options">
                                    <p>100GB Storage
                                    </p><p>All Themes
                                    </p><p>Access to Tutorials
                                    </p><p>Auto Backup
                                    </p><p>Extended Security
                                    </p><p>24/7 Support</p>
                                </div>
                                <div class="plan-button">
                                    <a href="#" class="button" style="background: #e96656" onMouseOver="this.style.backgroundColor = '#e88376'" onMouseOut="this.style.backgroundColor = '#e96656'"> sign up</a>
                                </div>
                            </div>
                        </div>								
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
		
    <section id="accent" class="default-section transparent-section">
        <div class="container">
            <div class="section-content">
                <div class="section-subcontent animated anHidden" data-animation="fadeInLeft">
                    <h3 class="section-title">Fast-Forward Your Technology Selection Process</h3>
                    <div class="section-description">See System Demos, Shortlist Solutions & Ask The Experts</div>
                </div>
                <div style="margin-bottom: 40px;" class="section-subcontent animated anHidden" data-animation="fadeInRight">
                    <div>
                        <img src="assets/images/ls_central_logo1.png" alt="LS Central Logo">
                        <img src="assets/images/ls_retail_logo1.png" alt="LS Retail Logo">
                        <img src="assets/images/bussines_central_logo1.png" alt="Business Central Logo">&nbsp;
                        <img src="assets/images/ms_nav_logo1.png" alt="Microsoft NAV Logo">&nbsp;
                    </div>
                    <div style="margin-top: 20px;">
                        <img src="assets/images/power_bi_logo1.png" alt="Power BI Logo">&nbsp;
                        <img src="assets/images/power_apps_logo1.png" alt="Power Apps Logo">&nbsp;
                        <img src="assets/images/sharepoint_logo1.png" alt="Sharepoint Logo">&nbsp;
                        <img src="assets/images/ms_office_logo1.png" alt="Microsoft Office Logo">
                    </div>
                </div>
                <div class="section-buttons animated anHidden" data-animation="fadeInLeft">
                    <a data-toggle="modal" data-target="#contact-us" title="contact us" class="button white-button">BEGIN CUSTOMIZING YOUR SOLUTION</a>
                </div>
            </div>
        </div>
    </section>

    <section id="team" class="team-section white-section default-section">
        <div class="container">
            <div class="section-content">
                <h2 class="section-title">Our Clients</h2>
                <div class="section-description">Below are just a few of the world class organizations that <span style="color: #FF533D"><b>Enovasions</b></span> proudly serves.</div>
                <div class="animated">
                    <div style="margin-bottom: 30px;" class="row">
                        <marquee scrollamount="10" loop="infinite">
                            <img src="assets/images/client_golden_logo.png" alt="Golden Client Logo">&nbsp;
                            <img src="assets/images/client_BDO_logo.png" alt="BDO Client Logo">&nbsp;
                            <img src="assets/images/client_naidu_logo.png" alt="Naidu Client Logo">&nbsp;
                            <img src="assets/images/client_nestle_logo.png" alt="Nestle Client Logo">&nbsp;
                            <img src="assets/images/client_manubhai_logo.png" alt="Manubhai Client Logo">&nbsp;
                            <img src="assets/images/client_westpac_Logo.png" alt="Westpac Client Logo">&nbsp;
                            <img src="assets/images/client_narhari_logo.png" alt="Narhari Client Logo">&nbsp;
                            <img src="assets/images/client_zar_logo.png" alt="Zar Logistics Client Logo">&nbsp;
                            <img src="assets/images/client_farmerjoe_logo.png" alt="Farmer Joe Client Logo">&nbsp;
                            <img src="assets/images/client_glass&mirror_logo.png" alt="Farmer Joe Client Logo">&nbsp;
                            <img src="assets/images/client_ajax_logo.png" alt="Farmer Joe Client Logo">&nbsp;
                        </marquee>
                        <!-- <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar1.png" alt="Michael Brown">
                                </div>
                                <h4 class="team-name">Michael Brown</h4>
                                <p class="team-position">ceo</p>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar2.png" alt="Sammy lee">
                                </div>
                                <h4 class="team-name">Sammy lee</h4>
                                <p class="team-position">lead designer</p>		
                            </div>
                        </div>		
                        
                        <div class="clearfix visible-xs-block"></div> 
                        
                        <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar3.png" alt="James Rolph">
                                </div>
                                <h4 class="team-name">James Rolph</h4>
                                <p class="team-position">manager</p>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar4.png" alt="Laura Izaura">
                                </div>
                                <h4 class="team-name">Laura Izaura</h4>
                                <p class="team-position">lead programer</p>		
                            </div>
                        </div>		
                        
                        <div class="clearfix visible-xs-block"></div> 
                        
                        <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar5.png" alt="Ben Robinson">
                                </div>
                                <h4 class="team-name">Ben Robinson</h4>
                                <p class="team-position">SEO-specialist</p>		
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_team  col-xs-6 col-sm-2 col-md-2 col-lg-2">		
                            <div class="team-box">
                                <div class="team-avatar">
                                    <img src="assets/images/avatar6.png" alt="Anna Wilson">
                                </div>
                                <h4 class="team-name">Anna Wilson</h4>
                                <p class="team-position">office-manager</p>
                            </div>
                        </div> -->
                    </div>
                    
                    <div class='clearfix'></div>
                </div>
            </div>
        </div>
    </section>

    <section id="subscribe" class="subscribe-section default-section transparent-section">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div style="text-align: left;" class="section-content animated anHidden" data-animation="fadeInLeft">
                        <h2 class="section-title">Why Enovasions?</h2>
                        <div class="section-description">
                            Enovasions Pte Limited, is one of the leading Microsoft Dynamics, Microsoft LS Retail in Fiji and Asia Pacific with more than a decade of experience in various industry vertical. We bring in industry and domain expertise coupled with deep implementation experience in enterprise business solutions space. We have a reputation of executing end-to-end implementations in ERP, HRMS &amp; Payroll, VMS, Retail and Business Intelligence applications and having a high degree of customer retention, repeat customer business and one of the firsts in certain industry verticals in Fiji. Domain centricity & vertical approach of Enovasions is the key core strength by virtue of which Enovasions is able to expand its customer base year on year.
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div style="text-align: left;" class="section-content animated anHidden" data-animation="fadeInRight">
                        <h2 class="section-title">Our Experience</h2>
                        <div class="section-description">
                            <ul>
                                <li>Microsoft Gold Certified Partner</li>
                                <li>Microsoft Gold Partner proficiency for Business Central(NAV), Azure, Power BI, O365 etc.</li>
                                <li>Strong Domain Expertise in Retail, E-Commerce Logistics and Manufacturing</li>
                                <li>LS Retail Central Gold Partner proficiency</li>
                                <li>High Volume Of Clients Engagements across Fiji &amp; Asia Pacific</li>
                                <li>Having Strong Functional and Technical Trained Resources</li></ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="lastnews" class="lastnews-section grey-section default-section">
        <div class="container">
            <div class="section-content">
                <h2 class="section-title">blog posts</h2>
                <div class="section-description animated anHidden" data-animation="fadeInRight">Keep in touch with the all the latest news and events</div>
                
                <div class="row">
                    <div class="lastnews-list" style="margin-bottom: 40px;">
                        <div data-animation="fadeInLeft">
                            <div *ngFor="let retailBlogs of _blog.lsretailblogs; let cnt = index; let even = even; let odd = odd">
                                <article *ngIf="cnt<=2">
                                    <div class="boxcard" [ngClass]="[((cnt+1)%3==0 && cnt!=1) ? 'odd' : '', ((cnt+1)>3) ? 'margin-top20px' : '']">
                                        <!-- <div class="entry-thumbnail">
                                            <a href="{{retailBlogs.link}}" ><img width="720" height="480" src="{{retailBlogs.thumbnail}}" alt="" sizes="(max-width: 720px) 100vw, 720px" /></a>
                                        </div> -->
                                        <header class="entry-header">
                                            <h2 class="entry-title h5">
                                                <a href="{{retailBlogs.link}}" rel="bookmark" target="_blank">{{retailBlogs.title}}</a>
                                            </h2>            
                                        </header> 
                                        <section class="entry-content">
                                            <div id="wrap-text" innerHtml="{{retailBlogs.description}}"></div>
                                        </section>
                                        <footer class="entry-footer">
                                            <div class="meta bottom">
                                                <span class="date-post h6 span-footer-left">{{retailBlogs.author}}</span>
                                                <span class="date-post h6 span-footer-right">{{retailBlogs.pubDate}}</span>
                                                <div class="clearfix"></div>
                                            </div>
                                        </footer>
                                    </div>
                                </article>
                            </div>
                        </div>
                        
                        <!-- <div id="post-2258" class="post col-xs-12 col-sm-3 col-md-3 col-lg-3 animated anHidden post-2258 type-post status-publish format-standard has-post-thumbnail hentry category-construction category-design category-inspiration"	data-animation="fadeInLeft">
                            <div class="entry-thumbnail">
                                <a href="#"><img width="265" height="260" src="assets/images/people-woman-coffee-meeting-265x260.jpg" class="attachment-mp-emmet-thumb-medium size-mp-emmet-thumb-medium wp-post-image" alt="" /></a>
                            </div>
                            <div class="entry-header">
                                <h5 class="entry-title">
                                    <a href="#" rel="bookmark">Clients are fuel for financial business</a>
                                </h5>
                            </div>
                            <div class="entry entry-content">
                                <p>Strong and smart financial planning strategies and management ideas are really just a small ...</p>
                            </div>
                        </div> -->
                        
                        <!-- <div id="post-2256" class="post col-xs-12 col-sm-3 col-md-3 col-lg-3 animated anHidden post-2256 type-post status-publish format-standard has-post-thumbnail hentry category-architecture category-inspiration tag-news tag-website" data-animation="fadeInLeft">
                            <div class="entry-thumbnail">
                                <a href="#"><img width="265" height="260" src="assets/images/apple-iphone-books-desk-265x260.jpg" class="attachment-mp-emmet-thumb-medium size-mp-emmet-thumb-medium wp-post-image" alt="" /></a>
                            </div>
                            <div class="entry-header">
                                <h5 class="entry-title">
                                    <a href="#" rel="bookmark">Offer trading services under your brand</a>
                                </h5>
                            </div>
                            <div class="entry entry-content">
                                <p>Today, white label solutions open up a lot of opportunities for private investors. Our compa...</p>
                            </div>
                        </div> -->
                        
                        <!-- <div id="post-2140" class="post col-xs-12 col-sm-3 col-md-3 col-lg-3 animated anHidden post-2140 type-post status-publish format-standard has-post-thumbnail hentry category-construction category-inspiration category-technology tag-news tag-review" data-animation="fadeInLeft">
                            <div class="entry-thumbnail">
                                <a href="#"><img width="265" height="260" src="assets/images/red-hands-woman-creative-265x260.jpg" class="attachment-mp-emmet-thumb-medium size-mp-emmet-thumb-medium wp-post-image" alt="" /></a>
                            </div>
                            <div class="entry-header">
                                <h5 class="entry-title">
                                    <a href="#" rel="bookmark">Common mistakes of the first startup project</a>
                                </h5>
                            </div>
                            <div class="entry entry-content">
                                <p>The mistakes of startups can be very depressing. Especially the ones that could be easily av...</p>
                            </div>
                        </div> -->
                        
                        <div class="clearfix"></div>
                    </div>
                </div>

                <div class="section-buttons">
                    <a routerLink="/blogs" title="view all blogs" class="button white-button">view all blogs</a>
                </div>

            </div>
        </div>
    </section>

    <section id="testimonials" class="testimonials-section transparent-section default-section">
        <div class="container">
            <div class="section-content">
                <!-- <h2 class="section-title">testimonials</h2>
                <div class="section-description">What our happy customers say</div> -->
                
                <div class="animated anHidden"  data-animation="fadeInUp">
                    <div class="row">
                        <div class="counter-wrapper">
                            <div class="counter col_third">
                                <i class="fa fa-users fa-2x"></i>
                                <h2 class="timer count-title count-number" data-counter="100"></h2>
                                <p class="count-text ">Employee Owned.</p>
                            </div>

                            <div class="counter col_third">
                                <i class="fa fa-handshake-o fa-2x"></i>
                                <h2 class="timer count-title count-number" data-counter="100"></h2>
                                <p class="count-text ">Fijian Owned.</p>
                            </div>

                            <div class="counter col_third end">
                                <i class="fa fa-user fa-2x"></i>
                                <h2 class="timer count-title count-number" data-counter="100"></h2>
                                <p class="count-text ">Employee Operated.</p>
                            </div>
                        </div>
                    
                        <!-- <div class="widget theme_widget_testimonial  col-xs-12 col-sm-12 col-md-4 col-lg-4">        
                            <div class="testimonial-box">
                                <div class="testimonial-content">
                                    <blockquote>
                                        I forgot about unneeded back and forth backend moves and simply enjoy my life. I now spend more time making the music I love. Emmet has a cool visual editor, so my editing work is simply a play.
                                    </blockquote>
                                </div>
                                <div class="testimonial-athor">
                                    <div class="testimonial-athor-avatar">
                                        <img src="assets/images/testimonial2.png" alt="Bryan Mitchell">
                                    </div>
                                    <div class="testimonial-athor-content">
                                        <h4 class="testimonial-athor-name">Bryan Mitchell</h4>
                                        <p class="testimonial-athor-position">Alabama solutions</p>	
                                    </div>	
                                </div>
                            </div>
                        </div>
                        
                        <div class="widget theme_widget_testimonial  col-xs-12 col-sm-12 col-md-4 col-lg-4">        
                            <div class="testimonial-box">
                                <div class="testimonial-content">
                                    <blockquote>
                                        I run a photographer blog and the Emmet theme simply became my saver! It&#039;s super fast and easy to customize. I&#039;ve got a lot of compliments from my readers, which is my biggest satisfaction!                    
                                    </blockquote>
                                </div>
                                <div class="testimonial-athor">
                                    <div class="testimonial-athor-avatar">
                                        <img src="assets/images/testimonial3.png" alt="Bryan Mitchell">
                                    </div>
                                    <div class="testimonial-athor-content">
                                        <h4 class="testimonial-athor-name">Bryan Mitchell</h4>
                                        <p class="testimonial-athor-position">INk studios</p>	
                                    </div>	
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div id="googlemap" class="googlemap-section">
        <div class="section-content">
            <div class="map_block">
                <!-- <div id="map" style='width:100%; height: 625px;' data-latidute='-18.143500501451367' data-longitude='178.42367433953783' data-zoom='17' data-description='<strong>Address:</strong> Level 6, FNPF Place, 343 Victoria Parade, Suva, Fiji<br /><strong>Phone:</strong> +679 330 4121<br /><strong>Open:</strong> 9:00 am - 5:00 pm'></div> -->
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22864.11283411948!2d-73.96468908098944!3d40.630720240038435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbg!4v1540447494452" width="100%" height="625px" frameborder="0" style="border:0" allowfullscreen></iframe> -->
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="625px" id="gmap_canvas" src="https://maps.google.com/maps?q=343%20victoria%20pde,%20suva&t=&z=18&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        <a href="https://fmovies2.org">fmovies</a><br>
                        <style>.mapouter{position:relative;text-align:right;height:100%;width:625x;}</style>
                        <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
                        <style>.gmap_canvas {overflow:hidden;background:none!important;height:625px;width:100%;}</style>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <section class="contact-section transparent-section default-section" id="contact">
        <div class="container">
            <div class="section-content animated anHidden" data-animation="fadeIn">
                <h2 class="section-title">Message form</h2>
                <div class="section-description">Contact us using the form below</div>																
                    <div class="contact-form">
                        <div class="row">
                            <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <input type="text" [(ngModel)]="message_data.Name" (keypress)="blockSpecialChar($event)" placeholder="Your Name" class="form-control input-box">
                            </div>

                            <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <input type="email" [(ngModel)]="message_data.Email" (blur)="validateEmail($event)" placeholder="Your Email" class="form-control input-box">
                            </div>
                            
                            <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <input type="text" [(ngModel)]="message_data.Subject" placeholder="Subject" class="form-control input-box">
                            </div>
                                
                            <div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <textarea [(ngModel)]="message_data.Message" class="form-control textarea-box" rows="9" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                            
                        <div class="form-group">
                            <button class="btn btn-primary theme-contuct-submit" (click)="messageForm(message_data)">Send Message</button>
                        </div>																	
                    </div>
            </div>
        </div>
    </section>			
</div>

