import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trendmicro',
  templateUrl: './trendmicro.component.html',
  styleUrls: ['./trendmicro.component.css']
})
export class TrendmicroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
